<template>
  <div class="mapping_setting_tap_wrap">
    <div class="mapping_setting_tap button" @click="clickSettingTab(1)">
      {{ $t("term.droneControl") }}
    </div>
    <!-- <div class="mapping_setting_tap button" @click="clickSettingTab(2)">지도</div> -->
    <div class="mapping_setting_tap button on">{{ $t("term.pointCloud") }}</div>
  </div>
  <div class="mapping_setting_content_wrap">
    <div class="mapping_setting_point_content">
      <div class="mapping_setting_sub_title">{{ $t("term.pointSize") }}</div>
      <input
        class="mapping_setting_point_content_input input"
        type="number"
        :placeholder="pointCloudParams.pointSize"
        v-model="inputPointSize"
        @blur="blurPointSize"
      />
    </div>
    <div class="mapping_setting_point_content">
      <div class="mapping_setting_sub_title">{{ $t("term.pointClarity") }}</div>
      <input
        class="mapping_setting_point_content_input input"
        type="number"
        :placeholder="pointCloudParams.opacity"
        v-model="inputPointOpacity"
        @blur="blurPointOpacity"
      />
    </div>
    <div class="mapping_setting_point_content">
      <div class="mapping_setting_sub_title">{{ $t("term.downSampling") }}</div>
      <input
        class="mapping_setting_point_content_input input"
        type="number"
        :placeholder="pointCloudParams.downSampling"
        v-model="inputDownSampling"
        @blur="blurDownSampling"
      />
    </div>
    <div class="mapping_setting_sub_title">{{ $t("term.pointColor") }}</div>
    <div class="mapping_setting_point_content_1">
      <div
        class="mapping_setting_radio radio"
        :class="{ on: pointCloudParams.mode == 'intensity' }"
        @click="clickPointCloudMode('intensity')"
      ></div>
      <div class="mapping_setting_radio_title">
        {{ $t("term.reflectivity") }}
      </div>
      <div
        class="mapping_setting_radio radio"
        :class="{ on: pointCloudParams.mode == 'height' }"
        @click="clickPointCloudMode('height')"
      ></div>
      <div class="mapping_setting_radio_title">{{ $t("term.height") }}</div>
      <div
        class="mapping_setting_radio radio"
        :class="{ on: pointCloudParams.mode == 'pointHexColor' }"
        @click="clickPointCloudMode('pointHexColor')"
      ></div>
      <div class="mapping_setting_radio_title">{{ $t("term.color") }}</div>
    </div>
    <div
      class="mapping_setting_point_content_1"
      v-if="pointCloudParams.mode == 'height'"
    >
      <div class="mapping_setting_point_content_title">
        {{ $t("term.minimumValue") }}
      </div>
      <input
        class="mapping_setting_point_content_div input"
        type="number"
        :placeholder="pointCloudParams.height.min"
        v-model="inputHeight.min"
        @blur="blurHeight('min')"
      />
      <div class="mapping_setting_point_content_unit">m</div>
    </div>
    <div
      class="mapping_setting_point_content_1"
      v-if="pointCloudParams.mode == 'height'"
    >
      <div class="mapping_setting_point_content_title">
        {{ $t("term.maximumValue") }}
      </div>
      <input
        class="mapping_setting_point_content_div input"
        type="number"
        :placeholder="pointCloudParams.height.max"
        v-model="inputHeight.max"
        @blur="blurHeight('max')"
      />
      <div class="mapping_setting_point_content_unit">m</div>
    </div>
    <div
      class="mapping_setting_point_content_1"
      v-if="pointCloudParams.mode == 'pointHexColor'"
    >
      <ColorPicker
        :label="$t('term.HEXValue')"
        :selectedHexColor="selectedHexColor"
        @colorChanged="onChangeColor($event)"
      />
    </div>
    <p class="red_text" v-if="pointCloudParams.mode == 'pointHexColor'">
      * {{ $t("message.darkerColorHidePointCloud") }}
    </p>
  </div>
</template>

<script>
import ColorPicker from "@/component/color-picker/ColorPicker.vue";

export default {
  emits: ["changeSettingTab", "changePointCloudParam"],
  components: { ColorPicker },
  data() {
    return {
      inputDownSampling: null,
      inputPointSize: null,
      inputPointOpacity: null,
      inputHeight: {
        min: null,
        max: null,
      },
    };
  },
  props: {
    pointCloudParams: Object,
    selectedHexColor: String,
  },
  methods: {
    onChangeColor(hex) {
      this.$emit("changePointCloudParam", "pointHexColor", hex);
    },
    clickSettingTab(tabNumber) {
      this.$emit("changeSettingTab", tabNumber);
    },
    clickPointCloudMode(mode) {
      this.$emit("changePointCloudParam", "mode", mode);
    },
    clickChangeColor(hex) {
      this.$emit("changePointCloudParam", "pointHexColor", hex);
    },
    blurDownSampling() {
      if (
        this.pointCloudParams.downSampling != this.inputDownSampling &&
        this.inputDownSampling
      ) {
        if (this.inputDownSampling <= 10 && this.inputDownSampling >= 1) {
          this.$emit(
            "changePointCloudParam",
            "downSampling",
            this.inputDownSampling
          );
        } else {
          this.$store.commit(
            "openAlert",
            `${this.$t("droneAlert.onlyAbleToEnterBetweenStartEndNumber", {
              startNumber: 1,
              endNumber: 10,
            })}`
          );
        }
      }
      this.inputDownSampling = null;
    },
    blurPointSize() {
      if (
        this.pointCloudParams.pointSize != this.inputPointSize &&
        this.inputPointSize != undefined
      ) {
        if (this.inputPointSize <= 10 && this.inputPointSize >= 1) {
          this.$emit("changePointCloudParam", "pointSize", this.inputPointSize);
        } else {
          this.$store.commit(
            "openAlert",
            `${this.$t("droneAlert.onlyAbleToEnterBetweenStartEndNumber", {
              startNumber: 1,
              endNumber: 10,
            })}`
          );
        }
      }
      this.inputPointSize = null;
    },
    blurPointOpacity() {
      if (
        this.pointCloudParams.opacity != this.inputPointOpacity &&
        this.inputPointOpacity != undefined
      ) {
        if (this.inputPointOpacity <= 1 && this.inputPointOpacity >= 0) {
          this.$emit(
            "changePointCloudParam",
            "opacity",
            this.inputPointOpacity
          );
        } else {
          this.$store.commit(
            "openAlert",
            this.$t("droneAlert.onlyAbleToEnterBetweenStartEndNumber", {
              startNumber: 1,
              endNumber: 10,
            })
          );
        }
      }
      this.inputPointOpacity = null;
    },
    blurHeight(type) {
      switch (type) {
        case "min":
          if (
            this.inputHeight.min != this.pointCloudParams.height.min &&
            this.inputHeight.min != undefined
          ) {
            if (this.inputHeight.min >= this.pointCloudParams.height.max) {
              this.$store.commit(
                "openAlert",
                `${this.$t("droneAlert.greaterThanMaxNumber")}\n${this.$t(
                  "droneAlert.enterLowerNumber"
                )}`
              );
            } else {
              this.$emit(
                "changePointCloudParam",
                "heightMin",
                this.inputHeight.min
              );
            }
          }
          this.inputHeight.min = null;
          break;
        case "max":
          if (
            this.inputHeight.max != this.pointCloudParams.height.max &&
            this.inputHeight.max != undefined
          ) {
            if (this.inputHeight.max <= this.pointCloudParams.height.min) {
              this.$store.commit(
                "openAlert",
                `${this.$t("droneAlert.lessThanMinimumNumber")}\n${this.$t(
                  "droneAlert.enterGreaterNumber"
                )}`
              );
            } else {
              this.$emit(
                "changePointCloudParam",
                "heightMax",
                this.inputHeight.max
              );
            }
          }
          this.inputHeight.max = null;
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style lang="scss"></style>
