export default class Waypoint {
  constructor(
    x,
    y,
    z,
    yaw,
    pitch,
    fitType,
    checked,
    completed,
    groupNum,
    mission
  ) {
    this.x = x;
    this.y = y;
    this.z = z;
    this.yaw = yaw;
    this.pitch = pitch;
    this.fitType = fitType;
    this.checked = checked;
    this.completed = completed;
    this.groupNum = groupNum;
    this.mission = mission;
  }

  setX(x) {
    this.x = x;
  }
  setY(y) {
    this.y = y;
  }
  setZ(z) {
    this.z = z;
  }
  setPosition(x, y, z) {
    this.x = x;
    this.y = y;
    this.z = z;
  }
  setYaw(yaw) {
    this.yaw = yaw;
  }
  setFitType(fitType) {
    this.fitType = fitType;
  }
  setChecked(checked) {
    this.checked = checked;
  }
  setCompleted(completed) {
    this.completed = completed;
  }
  setGroupNum(groupNum) {
    this.groupNum = groupNum;
  }

  getX() {
    return this.x;
  }
  getY() {
    return this.y;
  }
  getZ() {
    return this.z;
  }
  getPosition() {
    return { x: this.x, y: this.y, z: this.z };
  }
  getYaw() {
    return this.yaw;
  }
  getYawDegree() {
    let wpRotation = (this.yaw / Math.PI) * 180.0;
    if (wpRotation < 0) wpRotation = wpRotation + 360.0;

    if (wpRotation >= 0 && wpRotation <= 180)
      wpRotation = Math.abs(wpRotation - 180);
    else if (wpRotation > 180 && wpRotation <= 360)
      wpRotation = -wpRotation + 540;
    return wpRotation;
  }
  getFitType() {
    return this.fitType;
  }
  getChecked() {
    return this.checked;
  }
  getCompleted() {
    return this.completed;
  }
  getGroupNum() {
    return this.groupNum;
  }
}
