<template>
  <div class="important_message korean button">
    <p v-for="message in importantMessages" :key="message">
      {{ message.text }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    importantMessages: Array,
  },
};
</script>

<style lang="scss">
.important_message {
  @include flexbox(center, flex-start, column);
  gap: 8px;
  position: absolute;
  top: calc(50% - 25px);
  left: calc(50% - 250px);
  width: 500px;
  padding: 20px 12px;
  z-index: 90;
  font-size: 1.4rem;
  overflow: hidden;
  pointer-events: none;
  opacity: 0.7;
  white-space: pre-line;

  p {
    display: block;
  }
}
.important_message:hover {
  background-color: #171717;
  color: rgba(1, 250, 254, 1);
}
</style>
