<template>
  <component :is="$route.meta.layout || 'main'">
    <div class="main_fixed main korean">
      <div class="mapping_main_window" ref="mapping_cloud_viewer"></div>
      <MainImportantMessage
        v-if="importantMessage"
        :importantMessage="importantMessage"
      />
      <MainImportantMessageArray
        v-if="importantMessages?.length > 0"
        :importantMessages="importantMessages"
      />
      <MappingLeftVue>
        <MappingPointCloudControlVue
          @clickClear="clearPointCloud"
          @clickSave="savePointCloud"
        />
        <MappingStatusVue
          @changeSettingModalState="handleSettingModalState"
          @changeStatusModalState="handleStatusModalState"
          :droneData="droneData"
          :selectedDroneType="selectedDroneType"
        />
        <!-- <MappingCommandVue @clickArm="handleArm" @clickTakeOff="handleTakeOff" @clickMode="handleMode"
                         @clickRTL="handleRTL" @clickReboot="handleReboot" :droneData="droneData"/> -->
        <MappingCommandVue
          @clickRTL="handleRTL"
          @clickReboot="handleReboot"
          :droneData="droneData"
          :selectedDroneType="selectedDroneType"
        />
      </MappingLeftVue>
      <MappingPointCloudInfoVue :pointCloudInfo="pointCloudInfo" />
      <!-- <MappingMissionSummaryVue v-if="false" @seleteMission="seleteMission" @changeMissionModalState="handleMissionModalState" @missionStart="missionStart"
                              :missionList="missionList" :selectedMission="selectedMission" :waypointList="waypointList"/> -->
      <!-- <MappingMissionModalVue ref="mission_modal" @changeMissionModalState="handleMissionModalState"
                            @seleteMission="seleteMission" @saveMission="saveMission" @deleteMission="deleteMission"
                            @saveWaypoint="saveWaypoint" @deleteWaypoint="deleteWaypoint" @updateWaypoints='updateWaypoints'
                            :missionModalState="missionModalState" :missionList="missionList" :selectedMission="selectedMission"
                            :waypointList="waypointList"/> -->
      <MappingSettingModalVue
        v-if="settingModalState"
        @changeSettingModalState="handleSettingModalState"
      >
        <MappingSettingDroneVue
          v-if="settingTab == 1"
          @changeSettingTab="handleSettingTab"
          @selectDrone="selectDrone"
          @selectDroneType="selectDroneType"
          :droneList="droneList"
          :selectedDrone="selectedDrone"
          :droneTypeList="droneTypeList"
          :selectedDroneType="selectedDroneType"
        />
        <!-- <MappingSettingMapVue v-else-if="settingTab==2" @changeSettingTab="handleSettingTab" @changeMapMode="handleMapMode"/> -->
        <MappingSettingPointCloudVue
          v-else-if="settingTab == 3"
          @changeSettingTab="handleSettingTab"
          @changePointCloudParam="handlePointCloudParam"
          :pointCloudParams="pointCloudParams"
          :selectedHexColor="this.pointCloudParams.hexColor"
        />
      </MappingSettingModalVue>
      <MappingStatusModalVue
        @changeStatusModalState="handleStatusModalState"
        :statusModalState="statusModalState"
        :msgs="droneData.msg"
      />
      <!-- <div :class="mapClass" ref="mapping_map">
    </div>
    <div class="mapping_sub_window_footer">
      <div
        class="mapping_sub_window_footer_icon button"
        @click="handleMainMode"
      ></div>
      <div class="mapping_sub_window_footer_text">메인 화면 전환</div>
    </div> -->
    </div>
  </component>
</template>

<script>
// import { formatISO } from 'date-fns'

// import PCReportViewer from '@/module/PointCloud/PointCloudViewReport.js'
import PCMappingVievwer from '@/module/PointCloud/PointCloudViewMapping.js';
// import GoogleAPI from "@/module/Google/GoogleAPI.js"
import PCDSocket from '@/module/Communication/PCDSocket.js';
import DroneControl from '@/module/Drone/DroneControl';
import DroneMonitor from '@/module/Drone/DroneMonitor.js';

import Site from '@/model/Site.js';
import Map from '@/model/Map.js';
import Drone from '@/model/Drone.js';
import DroneType from '@/model/DroneType.js';
import DroneData from '@/model/MappingDroneData.js';
// import DroneParams from "@/model/DroneParams.js"
// import MappingMission from "@/model/MappingMission.js"
// import MappingWaypoint from "@/model/MappingWaypoint.js"
import PointCloudParams from '@/model/PointCloudParams.js';

import MainImportantMessage from '../Common/MainImportantMessage.vue';
import MappingLeftVue from './MappingLeft.vue';
import MappingPointCloudControlVue from './MappingPointCloudControl.vue';
import MappingStatusVue from './MappingStatus.vue';
import MappingCommandVue from './MappingCommand.vue';
import MappingPointCloudInfoVue from './MappingPointCloudInfo.vue';
// import MappingMissionSummaryVue from './MappingMissionSummary.vue'
// import MappingMissionModalVue from './MappingMissionModal.vue'
import MappingSettingModalVue from './MappingSettingModal.vue';
import MappingSettingDroneVue from './MappingSettingDrone.vue';
// import MappingSettingMapVue from './MappingSettingMap.vue'
import MappingSettingPointCloudVue from './MappingSettingPointCloud.vue';
import MappingStatusModalVue from './MappingStatusModal.vue';

import { mapState } from 'vuex';
import { markRaw } from 'vue';
import API from '@/shared/constant/api';
import MainImportantMessageArray from '../Common/MainImportantMessageArray.vue';

export default {
  components: {
    MainImportantMessage,
    MappingLeftVue,
    MappingPointCloudControlVue,
    MappingStatusVue,
    MappingCommandVue,
    MappingPointCloudInfoVue,
    // MappingMissionSummaryVue,
    // MappingMissionModalVue,
    MappingSettingModalVue,
    MappingSettingDroneVue,
    // MappingSettingMapVue,
    MappingSettingPointCloudVue,
    MappingStatusModalVue,
    MainImportantMessageArray,
  },
  data() {
    return {
      importantMessage: null, // 안쓰면 지워줘야함
      importantMessages: [],
      importantMessageTimer: null,
      pointCloudParams: new PointCloudParams(),
      pointCloudInfo: { pointNumber: 0, xArea: 0, yArea: 0 },
      // udpPort: null,
      // mainMode : 'pointcloud',
      missionModalState: false,
      settingModalState: false,
      settingTab: 1,
      statusModalState: false,
      droneData: new DroneData(),
      droneList: [],
      selectedDrone: new Drone(),
      droneTypeList: [],
      selectedDroneType: new DroneType(),
      // droneParams : new DroneParams(),
      // missionList : [],
      // selectedMission : new MappingMission({name : '새 미션',createdDatetime:formatISO(new Date())}),
      // waypointList : [],
      selectedHexColor: '',
      droneSocket: null,
      droneConnectionInterval: null,
      isMapSaving: false,
      gcsConnectionAlert: null,
      connectionAlert: null,
    };
  },
  computed: {
    ...mapState(['user', 'api', 'selectedFacility', 'siteList', 'apiPort']),
    // viewerClass() {
    //   return this.mainMode=='pointcloud'? 'mapping_main_window' : 'mapping_sub_window' ;
    // },
    // mapClass() {
    //   return this.mainMode=='map'? 'mapping_main_window' : 'mapping_sub_window';
    // },
  },
  watch: {
    settingModalState: {
      handler(settingModalState) {
        if (settingModalState) {
          this.statusModalState = false;
        }
      },
    },
    missionModalState: {
      handler(missionModalState) {
        if (missionModalState) {
          this.statusModalState = false;
        }
      },
    },
  },
  methods: {
    //############################### HANDLE ###############################
    handleSettingModalState(boolean) {
      this.settingModalState = boolean;
    },
    handleStatusModalState() {
      this.statusModalState = !this.statusModalState;
    },
    selectDroneType(droneType) {
      if (droneType) {
        this.selectedDroneType = droneType;
      } else {
        this.selectDrone();
        this.selectedDroneType = new DroneType();
        this.clearPointCloud(); // 포인트 클라우드 초기화
      }
    },
    selectDrone(drone) {
      if (this.selectedDroneType.id) {
        if (drone?.droneSerialNumber != this.selectedDrone.droneSerialNumber) {
          this.checkDroneStatus(drone);
        }
      } else {
        this.$store.commit('openAlert', this.$t('droneAlert.selectModuleTypeFirst'));
        this.selectedDrone = new Drone();
      }
    },
    checkDroneStatus(drone) {
      if (drone) {
        this.api.getDrone(this.checkDroneStatusCallback, drone.id);
      } else {
        this.selectedDrone = new Drone();
        if (this.connectionAlert) {
          // 30초 동안 아무 메시지 안들어오면 해제될 때 뜨는 알럿 clear
          clearTimeout(this.connectionAlert);
          this.connectionAlert = null;
        }
        if (this.gcsConnectionAlert) {
          clearTimeout(this.gcsConnectionAlert);
          this.gcsConnectionAlert = null;
        }
        this.connectDrone();
      }
    },
    checkDroneStatusCallback(data) {
      let result = data.data.result;
      if (result.droneStatus == 'disConnecting') {
        this.selectedDrone = new Drone(result);
      } else {
        this.$store.commit('openAlert', this.$t('droneAlert.alreadyInUseId'));
        this.selectedDrone = new Drone();
        this.stopDroneConnection();
      }
      this.connectDrone();
    },
    /**
     * 드론 연결 할 때 웹소켓으로 1초 마다 메세지 보내기
     */
    droneConnectWebsocket(droneSerialNumber) {
      this.droneSocket = new WebSocket(
        `${API.WEBSOCKET_ORIGIN}/ws/drones/${droneSerialNumber}`
      );

      this.droneSocket.onopen = () => {
        this.droneConnectionInterval = setInterval(() => {
          this.droneSocket.send(
            JSON.stringify({ message: JSON.stringify(droneSerialNumber) })
          );
        }, 1000);

        console.log(`Analysis Web Socket Open`);
      };
      this.droneSocket.onclose = () => {
        console.log(`Analysis Web Socket Close`);
      };
    },

    /**
     * 드론 연결 끊기 (웹소켓 종료)
     */
    stopDroneConnection() {
      // 타이머 정지
      if (this.droneConnectionInterval) {
        clearInterval(this.droneConnectionInterval);
        this.droneConnectionInterval = null;
      }

      // 웹소켓 닫기
      if (this.droneSocket) {
        this.api.putDrone(this.droneMonitor.module.id, 'disConnecting');
        this.droneSocket.close();
        console.log('웹소켓 연결 끊음');

        this.droneSocket = null; // 객체 초기화
      }
    },
    connectDrone() {
      if (this.droneMonitor) {
        this.api.putDrone(this.droneMonitor.module.id, 'disConnecting');

        this.stopDroneConnection();
        this.droneMonitor.closeMqtt();
        this.droneMonitor = null;
        this.droneControl = null;
        this.cloudViewer.deleteDroneModel();
        this.droneData = new DroneData();
        if (this.pcdSocket) this.pcdSocket.closeSocket();
        this.pcdSocket = null;
        this.clearPointCloud(); // pointCloud 초기화
      }
      if (this.selectedDrone.droneSerialNumber) {
        this.droneMonitor = new DroneMonitor(this.selectedDrone);
        this.api.putDrone(this.droneMonitor.module.id, 'connecting');
        this.droneConnectWebsocket(this.selectedDrone.droneSerialNumber);
        this.droneMonitor.subConnection(this.connectionCallback);
        this.droneMonitor.subGcsConnection(this.gcsConnectionCallback);
        this.droneMonitor.subSiriusState(this.siriusStateCallback);
        this.droneMonitor.subSiriusError(this.siriusErrorCallback);
        this.droneMonitor.subErrorMessage(this.errorMessageCallback);
        this.droneMonitor.subWarningMessage(this.warningMessageCallback);
        this.droneMonitor.subDataState(this.dataStateCallback);
        this.droneMonitor.subDataError(this.dataErrorCallback);
        this.droneMonitor.subSlamWarning(this.slamWarningCallback);
        this.droneMonitor.subGlobalPose(this.globalPoseCallback);
        this.droneMonitor.subSlamState(this.slamStateCallback);
        this.droneMonitor.subState(this.stateCallback);
        this.droneMonitor.subBattery(this.batteryCallback);
        this.droneMonitor.subStatusText(this.statusTextCallback);
        this.droneMonitor.subSensorState(this.sensorStateCallback);
        this.droneMonitor.subGroundSpeed(this.groundSpeedCallback);
        this.droneMonitor.subGpsMode(this.gpsModeCallback);
        this.droneMonitor.subGpsStatus(this.gpsStatusCallback);
        this.droneMonitor.subRc(this.rcCallback);
        this.droneMonitor.subLte(this.lteCallback);
        this.droneMonitor.subMapSaveComplete(this.mapSaveCompleteCallback);
        this.droneMonitor.readMsg();

        this.droneControl = new DroneControl(
          this.droneMonitor.mqttClient,
          this.selectedDrone,
          this.selectedFacility.id,
          this.selectedFacility.name
        );

        this.cloudViewer.createDroneModel();
        this.pcdSocket = new PCDSocket(this.selectedDrone.port);
        this.pcdSocket.pointCloudData(this.pointCloudDataCallback);
        this.pcdSocket.pointCloudPose(this.pointCloudPoseCallback);
      }
    },
    seleteMission(mission) {
      this.selectedMission = mission;
      this.readWaypoints();
    },
    handlePointCloudParam(type, value) {
      switch (type) {
        case 'mode':
          if (value == 'intensity') {
            this.cloudViewer.setUseHexColor(false);
            this.pointCloudParams.mode = 'intensity';
            this.cloudViewer.material.uniforms.colorMap.value = false;
          } else if (value == 'height') {
            this.pointCloudParams.mode = 'height';
            this.cloudViewer.setUseHexColor(false);
            this.cloudViewer.material.uniforms.colorMap.value = true;
          } else if (value == 'pointHexColor') {
            this.pointCloudParams.mode = 'pointHexColor';
            this.cloudViewer.setUseHexColor(true);
            this.cloudViewer.material.uniforms.colorMap.value = false;
          }
          break;
        case 'downSampling':
          this.pointCloudParams.downSampling = value;
          break;
        case 'pointSize':
          this.pointCloudParams.pointSize = value;
          this.cloudViewer.material.uniforms.size.value = value;
          break;
        case 'opacity':
          this.pointCloudParams.opacity = value;
          this.cloudViewer.material.uniforms.opacity.value = value;
          break;
        case 'heightMin':
          this.pointCloudParams.height.min = value;
          this.cloudViewer.material.uniforms.minHeight.value = value;
          break;
        case 'heightMax':
          this.pointCloudParams.height.max = value;
          this.cloudViewer.material.uniforms.maxHeight.value = value;
          break;
        case 'pointHexColor':
          this.pointCloudParams.hexColor = value;
          this.cloudViewer.setHexColor(value);
          break;
        default:
          break;
      }
    },
    clearPointCloud() {
      this.cloudViewer.positionsArray = [];
      this.cloudViewer.scene.clear();
      this.cloudViewer.init();

      if (this.droneControl) {
        this.cloudViewer.createDroneModel();
      }
      this.pointCloudInfo.pointNumber = 0;
      this.pointCloudInfo.xArea = 0;
      this.pointCloudInfo.yArea = 0;
    },
    savePointCloud() {
      if (this.droneControl) {
        if (!this.droneData.state.arming) {
          this.isMapSaving = true; // 맵 저장 중
          this.droneControl.pointCloudSave();
          this.handleImportantMessage(this.$t('droneAlert.savingMap'));
        } else {
          this.isMapSaving = false;
          this.$store.commit(
            'openAlert',
            `${this.$t('droneAlert.currentlyDroneEngineOn')}\n${this.$t(
              'droneAlert.turnOffEngineForSafeMapSave'
            )}`
          );
        }
      } else {
        this.isMapSaving = false;
        this.$store.commit(
          'openAlert',
          this.$t('droneAlert.pleaseSetSomethingDroneTypeAndId')
        );
      }
    },
    handleRTL() {
      if (this.droneControl) {
        if (this.droneData.state.takeOff) {
          if (this.droneData.mode != 'RTL') this.droneControl.returnHome();
          else this.$store.commit('openAlert', this.$t('droneAlert.droneIsOnRTLMode'));
        } else {
          this.$store.commit(
            'openAlert',
            `${this.$t('droneAlert.droneIsNotFlying')}\n${this.$t(
              'droneAlert.unableToUseMode',
              { mode: 'RTL' }
            )}`
          );
        }
      } else {
        this.$store.commit(
          'openAlert',
          `${this.$t('droneAlert.pleaseSetSomethingDroneTypeAndId')}`
        );
      }
    },
    handleReboot() {
      if (this.droneControl) {
        if (!this.droneData.state.arming) {
          if (confirm(this.$t('droneAlert.wannaReboot'))) this.droneControl.reboot();
          else return false;
        } else {
          this.$store.commit(
            'openAlert',
            `${this.$t('droneAlert.currentlyDroneEngineOn')}\n${this.$t(
              'droneAlert.turnOffEngineForSafeReboot'
            )}`
          );
        }
      } else {
        this.$store.commit(
          'openAlert',
          `${this.$t('droneAlert.pleaseSetSomethingDroneTypeAndId')}`
        );
      }
    },
    handleImportantMessage(message) {
      if (this.importantMessageTimer) clearTimeout(this.importantMessageTimer);
      this.importantMessage = message;
    },
    handleSettingTab(tabNumber) {
      this.settingTab = tabNumber;
    },
    //############################### UDP CALLBACK ###############################
    pointCloudDataCallback(data) {
      this.pointCloudSamplingCount++;
      if (this.pointCloudSamplingCount % this.pointCloudParams.downSampling == 0) {
        this.cloudViewer.createPointsShader(this.createPointsShaderCallback, data);
        this.pointCloudSamplingCount = 0;
      }
    },
    pointCloudPoseCallback(data) {
      this.cloudViewer.drawLine(data.p.x, data.p.z, -data.p.y);
    },
    createPointsShaderCallback(point) {
      this.pointCloudInfo.pointNumber += point.geometry.attributes.position.count;
      this.pointCloudInfo.xArea = this.cloudViewer.maxX - this.cloudViewer.minX;
      this.pointCloudInfo.yArea = this.cloudViewer.maxY - this.cloudViewer.minY;
    },
    //############################### MQTT CALLBACK ###############################
    /**
     * 드론 연결을 확인한다.
     * 30초 동안 토픽 들어오지 않으면 연결 끊는다.
     */
    connectionCallback() {
      if (this.connectionAlert) {
        clearTimeout(this.connectionAlert);
        this.connectionAlert = null;
      }
      this.connectionAlert = setTimeout(() => {
        if (this.gcsConnectionAlert) {
          clearTimeout(this.gcsConnectionAlert);
          this.gcsConnectionAlert = null;
        }
        if(navigator.onLine){
          // 모듈 연결 및 모든 드론 정보 초기화
          this.selectedDroneType = new DroneType();
          this.selectedDrone = new Drone();
          this.connectDrone();

          this.$store.commit(
            'openAlert',
            `${this.$t('droneAlert.moduleDisconnected')}\n${this.$t(
              'droneAlert.CheckCommunication'
            )}                                                                                             `
          );
        }
        else{
          this.$store.commit('openAlert', `${this.$t('droneAlert.gcsConnectionLost')}`);
        }
      }, 10000); // 10초
    },
    /**
     * 웹 서버와 gcs 연결을 확인한다.
     */
    gcsConnectionCallback() {
      if (this.gcsConnectionAlert) {
        clearTimeout(this.gcsConnectionAlert);
        this.gcsConnectionAlert = null;
      }
      this.gcsConnectionAlert = setTimeout(() => {
        if (this.connectionAlert) {
          clearTimeout(this.connectionAlert);
          this.connectionAlert = null;
        }
        this.$store.commit('openAlert', `${this.$t('droneAlert.gcsConnectionLost')}`);
      }, 10000);
      this.droneControl.moduleHeartBeat();
    },
    siriusStateCallback(data) {
      if (data.state == 'REQUESTING') {
        this.droneControl.initialize(
          this.selectedDroneType.name,
          'mapping',
          this.selectedDroneType.criteria
        );
        this.droneControl.ftpUploadInfo(this.ftpUploadInfo);
        this.droneControl.udpInfo(API.IP, this.selectedDrone.port);
        this.droneControl.sendMapInfo(
          this.selectedFacility.id,
          this.selectedFacility.name,
          this.$route.params.mapId
        );
      } else if (data.state == 'INITIALIZATION') {
        if (this.importantMessageTimer) clearTimeout(this.importantMessageTimer);
        this.importantMessage = this.$t('droneAlert.programIsRunning');
        this.importantMessageTimer = setTimeout(() => {
          this.importantMessage = null;
        }, 3000);
      } else if (data.state == 'STANBY') {
        if (this.importantMessageTimer) {
          clearTimeout(this.importantMessageTimer);
        }
        this.importantMessage = `${this.$t('droneAlert.programDone')} ${this.$t(
          'droneAlert.CheckFinalInspection'
        )}`;
        this.importantMessageTimer = setTimeout(() => {
          this.importantMessage = null;
        }, 3000);
      }
    },
    siriusErrorCallback(data) {
      this.statusText.push({
        severity: 0,
        text: `[Drone] ${data.message}`,
      });
      if (this.importantMessageTimer) clearTimeout(this.importantMessageTimer);
      this.importantMessage = `Drone ${data.message}`;
      this.importantMessageTimer = setTimeout(() => {
        this.importantMessage = null;
      }, 3000);
    },
    warningMessageCallback(data) {
      if (this.importantMessageTimer) {
        clearTimeout(this.importantMessageTimer);
        this.importantMessage = null;
      }

      if (data.value === 'MOBILEROBOT') {
        if (data.code === 'W1-1') {
          // 알럿 모달을 위해서 기존 메세지 배열 초기화
          this.importantMessages = [];
          // 배터리 잔량이 낮습니다. RTL을 실행
          return this.$store.commit(
            'openAlert',
            `${this.$t('droneAlert.moduleLowerBattery')}  ${this.$t(
              'droneAlert.changeToSomethingMode',
              { mode: 'RTL' }
            )}`
          );
        }
      }

      let newText;

      if (data.value === 'SLAM') {
        if (data.code === 'W1-1') {
          // 반복된 구조에 의해서 SLAM 실패 위험
          newText =
            `[Drone] ` +
            this.$t('droneAlert.riskOfSlamFailureExistsDueToRepeatedStructures');
        } else if (data.code === 'W1-2') {
          // 구조물의 부재에 의해서 SLAM 실패 위험
          newText =
            `[Drone] ` +
            this.$t('droneAlert.riskOfSlamFailureExistsDueToAbsenceOfStructure');
        } else if (data.code === 'W1-3') {
          // 위치추정을 위한 데이터가 부족합니다
          newText =
            `[Drone] ` +
            this.$t('droneAlert.lackOfLocalizationDataRecommendMoveToAnotherLocation');
        }
      }

      this.makeImportantMessagesArray(newText);
    },
    errorMessageCallback(data) {
      if (this.importantMessageTimer) {
        clearTimeout(this.importantMessageTimer);
        this.importantMessage = null;
      }

      let newText;

      if (data.value === 'SENSOR') {
        if (data.code === 'E1-1') {
          // Lidar
          newText =
            `[Drone] ` +
            this.$t('droneAlert.somethingHasBeenDisconnected', {
              something: 'Lidar',
            });
        } else if (data.code === 'E2-1') {
          // IMU
          newText =
            `[Drone] ` +
            this.$t('droneAlert.somethingHasBeenDisconnected', {
              something: 'IMU',
            });
        } else if (data.code === 'E3-1') {
          // ENCODRER
          newText =
            `[Drone] ` +
            this.$t('droneAlert.somethingHasBeenDisconnected', {
              something: 'Encoder',
            });
        }
      } else if (data.value === 'MOBILEROBOT') {
        // Drone
        if (data.code === 'E1-1') {
          // FCU
          newText =
            `[Drone] ` +
            this.$t('droneAlert.somethingHasBeenDisconnected', {
              something: 'FCU',
            });
        }
      } else if (data.value === 'SLAM') {
        if (data.code === 'E1-1') {
          // SLAM
          newText =
            `[Drone] ` +
            this.$t('droneAlert.somethingHasBeenFailed', {
              something: 'SLAM',
            });
        }
      } else if (data.value === 'MISSIONDEVICE') {
        if (data.code === 'E1-1') {
          // camera
          newText =
            `[Drone] ` +
            this.$t('droneAlert.somethingHasBeenDisconnected', {
              something: this.$t('term.camera'),
            });
        } else if (data.code === 'E2-1') {
          // gimbal
          newText =
            `[Drone] ` +
            this.$t('droneAlert.somethingHasBeenDisconnected', {
              something: 'Gimbal',
            });
        }
      } else if (data.value === 'MAPMANAGEMENT') {
        if (data.code.includes('E1')) {
          // upload
          newText =
            `[${data.code}] ` +
            this.$t('droneAlert.somethingHasBeenDisconnected', {
              something: this.$t('term.upload'),
            });
        } else if (data.code.includes('E2')) {
          // download
          newText =
            `[${data.code}] ` +
            this.$t('droneAlert.somethingHasBeenDisconnected', {
              something: this.$t('term.download'),
            });
        }
      }

      this.makeImportantMessagesArray(newText);
    },
    /**
     * 여러개 같이 떠야하는 알럿 메세지 실행 시켜줌
     * @param newText String
     */
    makeImportantMessagesArray(newText) {
      const randomId = Date.now().toString(36) + Math.random().toString(36).slice(2, 11);
      const message = {
        id: randomId,
        text: newText,
      };

      // 같은 droneNum과 text가 동시에 존재하는지 확인
      const isDuplicate = this.importantMessages.some((m) => m.text === newText);

      if (!isDuplicate) {
        this.importantMessages.push(message);

        setTimeout(() => {
          this.importantMessages = this.importantMessages.filter(
            (m) => m.id !== message.id
          );
        }, 10000);
      }
    },
    dataStateCallback(data) {
      if (this.importantMessageTimer) {
        clearTimeout(this.importantMessageTimer);
      }

      if (data.action === 'upload') {
        this.importantMessage =
          `[Drone] ` +
          this.$t('droneAlert.somethingUploading', {
            percentage: data.percentage,
          });
      } else if (data.action === 'download') {
        this.importantMessage =
          `[Drone] ` +
          this.$t('droneAlert.somethingDownloading', {
            percentage: data.percentage,
          });
      }

      this.importantMessageTimer = setTimeout(() => {
        this.importantMessage = null;
      }, 10000);
    },
    dataErrorCallback(data) {
      this.statusText.push({
        severity: 3,
        text: `[Drone] ${data.message}`,
      });
      if (this.importantMessageTimer) clearTimeout(this.importantMessageTimer);
      this.importantMessage = `Drone ${data.message}`;
      this.importantMessageTimer = setTimeout(() => {
        this.importantMessage = null;
      }, 3000);
    },
    slamWarningCallback(data) {
      this.statusText.push({
        severity: 4,
        text: `[Drone] ${data.message}`,
      });
      if (this.importantMessageTimer) clearTimeout(this.importantMessageTimer);
      this.importantMessage = `Drone ${data.message}`;
      this.importantMessageTimer = setTimeout(() => {
        this.importantMessage = null;
      }, 3000);
    },
    globalPoseCallback(data) {
      this.droneData.pose.lat = data.latitude.toFixed(5);
      this.droneData.pose.lon = data.longitude.toFixed(5);
      this.droneData.pose.alt = data.altitude.toFixed(2);
    },
    slamStateCallback(data) {
      if (!this.isMapSaving) {
        // 맵 저장중 상태가 아닐때에만 x,y 업데이트 해준다.
        this.droneData.pose.x = data.position_x;
        this.droneData.pose.y = data.position_y;
      }
      this.droneData.pose.roll =
        (Math.atan2(
          2 *
            (data.orientation_w * data.orientation_x +
              data.orientation_y * data.orientation_z),
          1 -
            2 *
              (data.orientation_x * data.orientation_x +
                data.orientation_y * data.orientation_y)
        ) *
          180) /
        Math.PI;
      this.droneData.pose.pitch =
        (Math.asin(
          Math.max(
            -1.0,
            Math.min(
              1.0,
              2 *
                (data.orientation_w * data.orientation_y -
                  data.orientation_z * data.orientation_x)
            )
          )
        ) *
          180) /
        Math.PI;
      this.droneData.pose.yaw =
        (Math.atan2(
          2 *
            (data.orientation_w * data.orientation_z +
              data.orientation_x * data.orientation_y),
          1 -
            2 *
              (data.orientation_y * data.orientation_y +
                data.orientation_z * data.orientation_z)
        ) *
          180) /
        Math.PI;

      if (this.cloudViewer.droneModel) {
        this.cloudViewer.droneModel.position.set(
          this.droneData.pose.x,
          data.position_z,
          -this.droneData.pose.y
        );
        this.cloudViewer.droneModel.rotation.order = 'YXZ';
        this.cloudViewer.droneModel.rotation.set(
          (-this.droneData.pose.pitch * Math.PI) / 180,
          (this.droneData.pose.yaw * Math.PI) / 180 - Math.PI / 2,
          0
        );
      }
    },
    stateCallback(data) {
      this.droneData.mode = data.mode;
      this.droneData.state.arming = Boolean(data.arming);
      if (this.droneData.state.arming) {
        if (!this.timeCheck) {
          this.armingTime = new Date();
          this.timeCheck = true;
        } else {
          let nowTime = new Date();
          let differenceInMilliseconds = nowTime - this.armingTime;
          let hours = Math.floor(differenceInMilliseconds / 1000 / 60 / 60);
          let minutes = Math.floor((differenceInMilliseconds / 1000 / 60) % 60);
          let seconds = Math.floor((differenceInMilliseconds / 1000) % 60);
          hours = String(hours).padStart(2, '0');
          minutes = String(minutes).padStart(2, '0');
          seconds = String(seconds).padStart(2, '0');

          this.droneData.flightTime = `${hours}:${minutes}:${seconds}`;
        }
      } else {
        this.timeCheck = false;
      }
      if (data.system_status == 3) {
        this.droneData.state.takeOff = false;
      } else if (data.system_status == 4) {
        this.droneData.state.takeOff = true;
      }
      //data.connected;
    },
    batteryCallback(data) {
      // if (this.selectDroneType.minVoltage &&this.selectDroneType.maxVoltage){
      //   this.droneData.battery =
      //     ((data.voltage-this.selectDroneType.minVoltage)/
      //     (this.selectDroneType.maxVoltage-this.selectDroneType.minVoltage)*
      //     100).toFixed(0);
      // }
      if (data.voltage) {
        this.droneData.battery = data.voltage.toFixed(2);
      }
    },
    statusTextCallback(data) {
      this.droneData.msg.push({ severity: data.severity, text: data.text });
    },
    sensorStateCallback(data) {
      this.droneData.sensorState.lidar = Boolean(data.lidar_connection);
      this.droneData.sensorState.imu = Boolean(data.imu_connection);
      this.droneData.sensorState.encoder = Boolean(data.encoder_connection);
    },
    groundSpeedCallback(data) {
      this.droneData.speed = data.speed.toFixed(2);
    },
    // gpsModeCallback(data) {
    //   this.droneParams.gpsMode = Boolean(data.gps_mode);
    // },
    gpsStatusCallback(data) {
      if (data.fix_type != '0') {
        this.droneData.gps.satellites = data.satellites;
        this.droneData.gps.eph = (data.eph / 100).toFixed(1);
      } else {
        this.droneData.gps.satellites = '-';
        this.droneData.gps.eph = '-.-';
      }
    },
    rcCallback(data) {
      this.droneData.rc = Boolean(data.rc_connection);
    },
    lteCallback(data) {
      let rsrp;
      let rssi;
      let rsrq;

      if (data.RSRP <= -100) rsrp = 0;
      else if (-100 < data.RSRP && data.RSRP <= -90) rsrp = 2;
      else if (-90 < data.RSRP && data.RSRP <= -80) rsrp = 3;
      else if (-80 < data.RSRP) rsrp = 4;

      if (data.RSRQ <= -20) rssi = 0;
      else if (-20 < data.RSRQ && data.RSRQ <= -15) rssi = 2;
      else if (-15 < data.RSRQ && data.RSRQ <= -10) rssi = 3;
      else if (-10 < data.RSRQ) rssi = 4;

      if (-data.RSSI <= -95) rsrq = 0;
      else if (-95 < -data.RSSI && -data.RSSI <= -85) rsrq = 1;
      else if (-85 < -data.RSSI && -data.RSSI <= -75) rsrq = 2;
      else if (-75 < -data.RSSI && -data.RSSI <= -65) rsrq = 3;
      else if (-65 < -data.RSSI) rsrq = 4;

      this.droneData.lte = Math.min(rsrp, rsrq, rssi); //0,1,2,3,4
    },
    // paramsCallback(data) {
    //   this.droneParams.maxSpeed = data.max_speed;
    //   this.droneParams.maxYawSpeed = data.max_yaw_speed;
    //   this.droneParams.obstacleDistance = data.obstacle_distance;
    //   this.droneParams.waypointSpeed = data.waypoint_speed;
    //   this.droneParams.waypointYawSpeed = data.waypoint_yaw_speed;
    // },
    mapSaveCompleteCallback() {
      this.droneData.msg.push({ severity: 6, text: 'Map save Complete' });
      if (this.importantMessageTimer) clearTimeout(this.importantMessageTimer);
      this.importantMessage = this.$t('droneAlert.mapSavingDone');
      this.importantMessageTimer = setTimeout(() => {
        this.importantMessage = null;
      }, 3000);
      this.api.postFtpUpload(this.selectedFacility.id, this.$route.params.mapId);

      // 모듈 연결 및 모든 드론 정보 초기화
      this.selectedDroneType = new DroneType();
      this.selectedDrone = new Drone();
      this.connectDrone();
      this.isMapSaving = false; // 맵 저장중 false처리
    },
    //################################## API ##################################
    getSite() {
      if (
        !this.selectedFacility ||
        this.selectedFacility.id != this.$route.params.facilityId
      ) {
        this.api.getSite(this.getSiteCallback, this.$route.params.facilityId);
      } else {
        this.getMaps();
      }
    },
    getSiteCallback(data) {
      let result = data.data.result;
      this.$store.dispatch('setSelectedFacility', new Site(result));
      this.getMaps();
    },
    getMaps() {
      this.api.getMaps(this.getMapsCallback, this.$route.params.facilityId);
    },
    getMapsCallback(data) {
      this.selectedFacility.mapList = [];
      let result = data.data.result;
      let mapList = [];
      if (result) {
        result.sort((a, b) => a.createdDatetime - b.createdDatetime);
        for (let i = 0; i < result.length; i++) {
          mapList.push(new Map(result[i]));
        }
        this.selectedFacility.mapList = mapList;
        this.$store.commit('setSelectedFacilityMap', null);
      }
    },
    // saveMission(missionName){
    //   this.api.postMappingMission(this.createWaypoints, this.$route.params.facilityId,  this.$route.params.mapId, missionName, formatISO(new Date()));
    // },
    // deleteMission(mapId, missionId){
    //   if(missionId == this.selectedMission.id){
    //     this.selectedMission = new MappingMission({name:'새 미션',facilityMapId:this.$route.params.mapId,createdDatetime:formatISO(new Date())});
    //   }
    //   this.api.deleteMappingMission(this.readMission, this.$route.params.facilityId, mapId, missionId);
    // },
    // readMission(){
    //   this.missionList = [];
    //   this.missionList.push(new MappingMission({name:'새 미션',facilityMapId:this.$route.params.mapId,createdDatetime:formatISO(new Date())}));
    //   this.mappingMissionCount = 0;
    //   for(let i=0; i<this.selectedFacility.mapList.length; i++){
    //     this.api.getMappingMissions(this.getMappingMissionsCallback,this.$route.params.facilityId,this.selectedFacility.mapList[i].id);
    //   }
    // },
    // getMappingMissionsCallback(data){
    //   this.mappingMissionCount++;
    //   let result = data.data.result;
    //   if(result){
    //     for(let i=0; i<result.length; i++){
    //     this.missionList.push(new MappingMission(result[i]));
    //     }
    //   }
    //   if(this.mappingMissionCount == this.selectedFacility.mapList.length){
    //     if(this.selectedMission.name != '새 미션'){
    //       this.selectedMission = this.missionList.find((mission)=> mission.id == this.selectedMission.id);
    //     }
    //     else{
    //       this.selectedMission = new MappingMission({name:'새 미션',facilityMapId:this.$route.params.mapId,createdDatetime:formatISO(new Date())});
    //     }
    //     this.readWaypoints();
    //   }
    // },
    // saveWaypoint(newWaypoint){
    //   this.waypointList = [...this.waypointList, newWaypoint];
    //   this.updateMarker();
    //   nextTick(()=>{
    //     this.$refs.mission_modal.updateMarker();
    //   })
    // },
    // deleteWaypoint(waypointIndex){
    //   this.waypointList.splice(waypointIndex, 1);
    //   this.waypointList.forEach((item, index)=>{
    //     item.no = index + 1;
    //   });
    //   this.updateMarker();
    //   nextTick(()=>{
    //     this.$refs.mission_modal.updateMarker();
    //   })
    // },
    // createWaypoints(data){
    //   for(let i=0; i<this.waypointList.length; i++){
    //     this.waypointList[i].mappingMissionId = data.data.result.id;
    //   }
    //   this.api.postMappingWaypoints(this.readMission,this.$route.params.facilityId, data.data.result.facilityMapId, data.data.result.id, this.waypointList);
    // },
    // updateWaypoints(){
    //   this.api.putMappingWaypoints(this.readMission,this.$route.params.facilityId, this.selectedMission.facilityMapId, this.selectedMission.id, this.waypointList);
    // },
    // readWaypoints(){
    //   this.waypointList = [];
    //   if(this.selectedMission.name != "새 미션"){
    //     this.api.getMappingWaypoints(this.getMappingWaypointsCallback,this.$route.params.facilityId,this.selectedMission.facilityMapId,this.selectedMission.id);
    //   }
    //   else{
    //     this.updateMarker();
    //     nextTick(()=>{
    //       this.$refs.mission_modal.updateMarker();
    //     })
    //   }
    // },
    // getMappingWaypointsCallback(data){
    //   let result = data.data.result;
    //   if(result){
    //     for(let i=0; i<result.length; i++){
    //      this.waypointList.push(new MappingWaypoint(result[i]));
    //     }
    //   }
    //   this.updateMarker();
    //   nextTick(()=>{
    //     this.$refs.mission_modal.updateMarker();
    //   })
    // },
    // updateMarker(){
    //   this.googleMap.clearMarkers();
    //   for(let i=0; i<this.waypointList.length; i++){
    //     this.googleMap.addMarker({lat : parseFloat(this.waypointList[i].lat), lng : parseFloat(this.waypointList[i].lon)}, false, true);
    //   }
    // },
    // updateDroneType(body){
    //   this.api.putDroneType(this.readDroneTypes,this.selectedDroneType.id, body)
    // },
    getDrones() {
      this.api.getDrones(this.getDronesCallback, this.user);
    },
    getDronesCallback(data) {
      let result = data.data.result;
      this.droneList = [];
      for (let i = 0; i < result.length; i++) {
        this.droneList.push(new Drone(result[i]));
      }
    },
    getDroneTypes() {
      this.api.getDronesType(this.getDronesTypeCallback);
    },
    getDronesTypeCallback(data) {
      let result = data.data.result;
      this.droneTypeList = [];
      for (let i = 0; i < result.length; i++) {
        this.droneTypeList.push(new DroneType(result[i]));
      }
    },
    ftpCredentialsCallback(data) {
      this.ftpUploadInfo = JSON.stringify(data.data.result);
    },
    cleanUp() {
      if (this.droneMonitor) {
        this.stopDroneConnection(); // 페이지 이동시에도 웹소켓 끊기도록
        this.droneMonitor.closeMqtt();
        this.droneMonitor = null;
      }
      if (this.droneControl) this.droneControl = null;
      if (this.pcdSocket) this.pcdSocket.closeSocket();
      if (this.connectionAlert) {
        clearTimeout(this.connectionAlert);
        this.connectionAlert = null;
      }
      if (this.gcsConnectionAlert) {
        clearTimeout(this.gcsConnectionAlert);
        this.gcsConnectionAlert = null;
      }
      if (this.importantMessage) {
        clearTimeout(this.importantMessage);
        this.importantMessage = null;
      }
    },
  },
  created() {
    this.importantMessageTimer;
    this.pointCloudSamplingCount = 0;
    this.timeCheck = false;
    this.armingTime = 0;
  },
  mounted() {
    this.cloudViewer = markRaw(
      new PCMappingVievwer(this.$refs.mapping_cloud_viewer, this.pointCloudParams)
    );
    // this.googleMap = markRaw(new GoogleAPI(this.$refs.mapping_map, false, false));
    this.api.putUser({ taskStatus: 'mapping' }, this.user.id);
    this.getSite();
    this.getDroneTypes();
    this.getDrones();
    // this.readDroneTypes();
    this.api.ftpCredentials(
      this.ftpCredentialsCallback,
      this.$route.params.facilityId,
      this.$route.params.mapId
    );
  },
  beforeUnmount() {
    this.cleanUp();
  },
};
</script>

<style lang="scss">
.main {
  background: #000;
}
.mapping_main_window {
  position: relative;
  width: 100vw;
  height: 100vh;
}
.mapping_sub_window {
  position: absolute;
  right: 15px;
  top: 85px;
  width: calc(100% / 11 * 3);
  height: calc(100% / 8 * 3);
  background: rgba(23, 23, 23, 0.85);
  box-shadow: $main_box_shadow;
  border-radius: 10px;
  z-index: 10;
  max-width: 370px;
  max-height: 370px;
}
/* .mapping_sub_window_footer{
  display: flex;
  align-items: center;
  position: absolute;
  right: 15px;
  top: 90px;
  width: calc(100% / 11 * 3);
  height: 30px;
  padding: 10px 0px 0px 10px;
  z-index: 11;
  max-width: 370px;
}
.mapping_sub_window_footer_icon {
  width: 30px;
  height: 30px;
  background-image: url("@/../public/images/toggle.svg");
  background-size: 15px 15px;
}
.mapping_sub_window_footer_icon:hover {
  background-image: url("@/../public/images/toggle_hover.svg");
  background-size: 15px 15px;
}
.mapping_sub_window_footer_text {
  padding-left: 5px;
  color:white;
  font-size: 15px;
} */
</style>
