import API from "@/shared/constant/api";

class DroneMonitor {
  constructor(module, droneNum) {
    this.module = module;
    this.droneNum = droneNum;
    this.mqttClient = mqtt.connect(`${API.MQTT_ORIGIN}`);
    this.mqttClient.on('connect', function () {
      console.log(`ID : ${module.droneSerialNumber}이 연결되었습니다.`);
    });
    window.onbeforeunload = () => {
      if (this.mqttClient) {
        this.mqttClient.end();
        console.log(`ID : ${module.droneSerialNumber}이 연결 해제되었습니다.`);
      }
    };
  }
  closeMqtt() {
    this.mqttClient.end();
    console.log(`ID : ${this.module.droneSerialNumber}이 연결 해제되었습니다.`);
  }
  /**
   * 드론과 연결되었는지 확인
   */
  subConnection(callback) {
    this.mqttClient.subscribe(`/from/${this.module.droneSerialNumber}/HEARTBEAT`);
    this.connectionCallback = callback;
  }
  /**
   * 웹 서버와 gcs가 연결되어있는지 확인
   */
  subGcsConnection(callback) {
    this.mqttClient.subscribe(`/from/${this.module.droneSerialNumber}/GCS_CONNECTION`);
    this.gcsConnectionCallback = callback;
  }
  subRtl(callback) {
    this.mqttClient.subscribe(`/from/${this.module.droneSerialNumber}/RTL_MESSAGE`);
    this.rtlCallback = callback;
  }
  subSiriusState(callback) {
    this.mqttClient.subscribe(`/from/${this.module.droneSerialNumber}/SIRIUS_STATE`);
    this.siriusStateCallback = callback;
  }
  subInitializationList(callback) {
    this.mqttClient.subscribe(
      `/from/${this.module.droneSerialNumber}/INITIALIZATION_LIST`
    );
    this.initializationListCallback = callback;
  }
  subSiriusError(callback) {
    this.mqttClient.subscribe(`/from/${this.module.droneSerialNumber}/SIRIUS_ERROR`);
    this.siriusErrorCallback = callback;
  }
  subDataState(callback) {
    this.mqttClient.subscribe(`/from/${this.module.droneSerialNumber}/DATA_STATE`);
    this.dataStateCallback = callback;
  }
  subDataError(callback) {
    this.mqttClient.subscribe(`/from/${this.module.droneSerialNumber}/DATA_ERROR`);
    this.dataErrorCallback = callback;
  }
  subSlamWarning(callback) {
    this.mqttClient.subscribe(`/from/${this.module.droneSerialNumber}/SLAM_WARNING`);
    this.slamWarningCallback = callback;
  }
  subLocalizationState(callback) {
    this.mqttClient.subscribe(
      `/from/${this.module.droneSerialNumber}/LOCALIZATION_STATE`
    );
    this.localizationStateCallback = callback;
  }
  subLocalizationWarning(callback) {
    this.mqttClient.subscribe(
      `/from/${this.module.droneSerialNumber}/LOCALIZATION_WARNING`
    );
    this.localizationWarningCallback = callback;
  }
  subWaypointError(callback) {
    this.mqttClient.subscribe(`/from/${this.module.droneSerialNumber}/WAYPOINT_ERROR`);
    this.waypointErrorCallback = callback;
  }
  subGlobalPose(callback) {
    this.mqttClient.subscribe(`/from/${this.module.droneSerialNumber}/GPS_STATE`);
    this.globalPoseCallback = callback;
  }
  /**
   * ! : 새로 추가된 토픽
   */
  subMissionState(callback) {
    this.mqttClient.subscribe(`/from/${this.module.droneSerialNumber}/MISSION_STATE`);
    this.missionStateCallback = callback;
  }
  subLocalPose(callback) {
    this.mqttClient.subscribe(`/from/${this.module.droneSerialNumber}/MOBILEROBOT_POSE`);
    this.localPoseCallback = callback;
  }
  subState(callback) {
    this.mqttClient.subscribe(`/from/${this.module.droneSerialNumber}/MOBILEROBOT_STATE`);
    this.stateCallback = callback;
  }
  subBattery(callback) {
    this.mqttClient.subscribe(
      `/from/${this.module.droneSerialNumber}/MOBILEROBOT_BATTERY`
    );
    this.batteryCallback = callback;
  }
  subStatusText(callback) {
    this.mqttClient.subscribe(
      `/from/${this.module.droneSerialNumber}/MOBILEROBOT_STATUS`
    );
    this.statusTextCallback = callback;
  }
  subSensorState(callback) {
    this.mqttClient.subscribe(`/from/${this.module.droneSerialNumber}/SENSOR_STATE`);
    this.sensorStateCallback = callback;
  }
  subGroundSpeed(callback) {
    this.mqttClient.subscribe(`/from/${this.module.droneSerialNumber}/MOBILEROBOT_SPEED`);
    this.groundSpeedCallback = callback;
  }
  subGpsMode(callback) {
    this.mqttClient.subscribe(`/from/${this.module.droneSerialNumber}/GPS_MODE`);
    this.gpsModeCallback = callback;
  }
  subGpsStatus(callback) {
    this.mqttClient.subscribe(`/from/${this.module.droneSerialNumber}/GPS_STATUS`);
    this.gpsStatusCallback = callback;
  }
  subRc(callback) {
    this.mqttClient.subscribe(`/from/${this.module.droneSerialNumber}/MOBILEROBOT_RC`);
    this.rcCallback = callback;
  }
  subLte(callback) {
    this.mqttClient.subscribe(`/from/${this.module.droneSerialNumber}/LTE_STATE`);
    this.lteCallback = callback;
  }
  // subArrival(callback){
  //   this.mqttClient.subscribe(`/from/${this.module.droneSerialNumber}/ARRIVAL`);
  //   this.arrivalCallback = callback;
  // }
  subMapSaveComplete(callback) {
    this.mqttClient.subscribe(`/from/${this.module.droneSerialNumber}/MAP_SAVE_COMPLETE`);
    this.mapSaveCompleteCallback = callback;
  }
  // sub_ftp_info(callback){
  //   this.mqtt_client.subscribe(`/from/${this.module.droneSerialNumber}/FTP_INFO`);
  //   this.ftp_info_callback = callback;
  // }
  // subParams(callback){
  //   this.mqttClient.subscribe(`/from/${this.module.droneSerialNumber}/PARAMS`);
  //   this.paramsCallback = callback;
  // }
  // sub_thumbnail(callback){
  //   this.mqtt_client.subscribe(`/from/${this.client_id}/THUMBNAIL`);
  //   this.thumbnail_callback = callback;
  // }
  subSlamState(callback) {
    this.mqttClient.subscribe(`/from/${this.module.droneSerialNumber}/SLAM_STATE`);
    this.slamStateCallback = callback;
  }
  subStorage(callback) {
    this.mqttClient.subscribe(`/from/${this.module.droneSerialNumber}/STORAGE_STATE`);
    this.storageCallback = callback;
  }
  // sub_gimbal_pitch(callback){
  //   this.mqtt_client.subscribe(`/from/${this.client_id}/GIMBAL_PITCH`);
  //   this.gimbal_pitch_callback = callback;
  // }
  subDjiConnection(callback) {
    this.mqttClient.subscribe(`/from/${this.module.droneSerialNumber}/DJI_CONNECTION`);
    this.djiConnectionCallback = callback;
  }
  subDjiModulePoseStop(callback) {
    this.mqttClient.subscribe(`/from/${this.module.droneSerialNumber}/DJI_INIT_POSE`);
    this.djiModulePoseStopCallback = callback;
  }
  subWaypoint(callback) {
    this.mqttClient.subscribe(`/from/${this.module.droneSerialNumber}/WAYPOINT`);
    this.waypointCallback = callback;
  }
  subTempWaypoint(callback) {
    this.mqttClient.subscribe(`/from/${this.module.droneSerialNumber}/WAYPOINT_PATH`);
    this.tempWaypointCallback = callback;
  }
  /**
   * start : 구 드론 데이터 관리
   */
  subDirectory(callback) {
    this.mqttClient.subscribe(
      `/from/${this.module.droneSerialNumber}/RESPONSE_DIRECTORY`
    );
    this.directoryCallback = callback;
  }
  subErase(callback) {
    this.mqttClient.subscribe(`/from/${this.module.droneSerialNumber}/RESPONSE_ERASE`);
    this.eraseCallback = callback;
  }
  subCopy(callback) {
    this.mqttClient.subscribe(`/from/${this.module.droneSerialNumber}/RESPONSE_COPY`);
    this.copyCallback = callback;
  }
  subUSB(callback) {
    this.mqttClient.subscribe(`/from/${this.module.droneSerialNumber}/RESPONSE_USB`);
    this.usbCallback = callback;
  }
  subStop(callback) {
    this.mqttClient.subscribe(`/from/${this.module.droneSerialNumber}/MISSION_STOP`);
    this.stopCallback = callback;
  }
  /**
   * end : 구 드론 데이터 관리
   */
  /**
   * 새로운 드론 데이터 관리
   */
  subDataTransfer(callback) {
    this.mqttClient.subscribe(`/from/${this.module.droneSerialNumber}/DATA_TRANSFER`);
    this.dataTransferCallback = callback;
  }
  subCameraShootPose(callback) {
    /**
     * position_x, position_y, position_z, orientation_roll, orientation_pitch, orientation_yaw
     */
    this.mqttClient.subscribe(`/from/${this.module.droneSerialNumber}/CAMERA_SHOOT_POSE`);
    this.cameraShootPoseCallback = callback;
  }
  subErrorMessage(callback) {
    this.mqttClient.subscribe(`/from/${this.module.droneSerialNumber}/ERROR`);
    this.errorMessageCallback = callback;
  }
  subWarningMessage(callback) {
    this.mqttClient.subscribe(`/from/${this.module.droneSerialNumber}/WARNING`);
    this.warningMessageCallback = callback;
  }
  readMsg() {
    this.mqttClient.on('message', (topic, message) => {
      try {
        var data = JSON.parse(message);
        if (
          topic == `/from/${this.module.droneSerialNumber}/HEARTBEAT` &&
          this.connectionCallback
        ) {
          if (this.droneNum != undefined) {
            this.connectionCallback(this.droneNum);
          } else {
            this.connectionCallback();
          }
        } else if (
          topic == `/from/${this.module.droneSerialNumber}/GCS_CONNECTION` &&
          this.gcsConnectionCallback
        ) {
          if (this.droneNum != undefined) {
            this.gcsConnectionCallback(this.droneNum);
          } else {
            this.gcsConnectionCallback();
          }
        } else if (
          topic == `/from/${this.module.droneSerialNumber}/RTL_MESSAGE` &&
          this.rtlCallback
        ) {
          if (this.droneNum != undefined) {
            this.rtlCallback(this.droneNum);
          } else {
            this.rtlCallback();
          }
        } else if (
          topic == `/from/${this.module.droneSerialNumber}/SIRIUS_STATE` &&
          this.siriusStateCallback
        ) {
          if (this.droneNum != undefined) {
            this.siriusStateCallback(data, this.droneNum);
          } else {
            this.siriusStateCallback(data);
          }
        } else if (
          topic == `/from/${this.module.droneSerialNumber}/SIRIUS_ERROR` &&
          this.siriusErrorCallback
        ) {
          if (this.droneNum != undefined) {
            this.siriusErrorCallback(data, this.droneNum);
          } else {
            this.siriusErrorCallback(data);
          }
        } else if (
          topic == `/from/${this.module.droneSerialNumber}/DATA_STATE` &&
          this.dataStateCallback
        ) {
          if (this.droneNum != undefined) {
            this.dataStateCallback(data, this.droneNum);
          } else {
            this.dataStateCallback(data);
          }
        } else if (
          topic == `/from/${this.module.droneSerialNumber}/DATA_ERROR` &&
          this.dataErrorCallback
        ) {
          if (this.droneNum != undefined) {
            this.dataErrorCallback(data, this.droneNum);
          } else {
            this.dataErrorCallback(data);
          }
        } else if (
          topic == `/from/${this.module.droneSerialNumber}/SLAM_WARNING` &&
          this.slamWarningCallback
        ) {
          if (this.droneNum != undefined) {
            this.slamWarningCallback(data, this.droneNum);
          } else {
            this.slamWarningCallback(data);
          }
        } else if (
          topic == `/from/${this.module.droneSerialNumber}/LOCALIZATION_STATE` &&
          this.localizationStateCallback
        ) {
          if (this.droneNum != undefined) {
            this.localizationStateCallback(data, this.droneNum);
          } else {
            this.localizationStateCallback(data);
          }
        } else if (
          topic == `/from/${this.module.droneSerialNumber}/LOCALIZATION_WARNING` &&
          this.localizationWarningCallback
        ) {
          if (this.droneNum != undefined) {
            this.localizationWarningCallback(data, this.droneNum);
          } else {
            this.localizationWarningCallback(data);
          }
        } else if (
          topic == `/from/${this.module.droneSerialNumber}/WAYPOINT_ERROR` &&
          this.waypointErrorCallback
        ) {
          this.waypointErrorCallback(data, this.droneNum);
        } else if (
          topic == `/from/${this.module.droneSerialNumber}/GPS_STATE` &&
          this.globalPoseCallback
        ) {
          this.globalPoseCallback(data);
        } else if (
          topic == `/from/${this.module.droneSerialNumber}/MISSION_STATE` &&
          this.missionStateCallback
        ) {
          this.missionStateCallback(data, this.droneNum);
        } else if (
          topic == `/from/${this.module.droneSerialNumber}/MOBILEROBOT_POSE` &&
          this.localPoseCallback
        ) {
          this.localPoseCallback(data, this.droneNum);
        } else if (
          topic == `/from/${this.module.droneSerialNumber}/MOBILEROBOT_STATE` &&
          this.stateCallback
        ) {
          this.stateCallback(data, this.droneNum);
        } else if (
          topic == `/from/${this.module.droneSerialNumber}/MOBILEROBOT_BATTERY` &&
          this.batteryCallback
        ) {
          this.batteryCallback(data, this.droneNum);
        } else if (
          topic == `/from/${this.module.droneSerialNumber}/MOBILEROBOT_STATUS` &&
          this.statusTextCallback
        ) {
          this.statusTextCallback(data, this.droneNum);
        } else if (
          topic == `/from/${this.module.droneSerialNumber}/SENSOR_STATE` &&
          this.sensorStateCallback
        ) {
          this.sensorStateCallback(data, this.droneNum);
        } else if (
          topic == `/from/${this.module.droneSerialNumber}/MOBILEROBOT_SPEED` &&
          this.groundSpeedCallback
        ) {
          this.groundSpeedCallback(data, this.droneNum);
        } else if (
          topic == `/from/${this.module.droneSerialNumber}/GPS_MODE` &&
          this.gpsModeCallback
        ) {
          this.gpsModeCallback(data, this.droneNum);
        } else if (
          topic == `/from/${this.module.droneSerialNumber}/GPS_STATUS` &&
          this.gpsStatusCallback
        ) {
          this.gpsStatusCallback(data);
        } else if (
          topic == `/from/${this.module.droneSerialNumber}/MOBILEROBOT_RC` &&
          this.rcCallback
        ) {
          this.rcCallback(data);
        } else if (
          topic == `/from/${this.module.droneSerialNumber}/LTE_STATE` &&
          this.lteCallback
        ) {
          this.lteCallback(data);
        }
        // else if(topic == `/from/${this.module.droneSerialNumber}/ARRIVAL` && this.arrivalCallback){
        //   this.arrivalCallback(data,this.droneNum);
        // }
        //       else if(topic == `/from/${this.client_id}/FTP_INFO` && this.ftp_info_callback){
        //         this.ftp_info_callback(data);
        //       }
        // else if(topic == `/from/${this.module.droneSerialNumber}/PARAMS` && this.paramsCallback){
        //   this.paramsCallback(data);
        // }
        //       else if(topic == `/from/${this.client_id}/THUMBNAIL` && this.thumbnail_callback){
        //         this.thumbnail_callback(data);
        //       }
        else if (
          topic == `/from/${this.module.droneSerialNumber}/SLAM_STATE` &&
          this.slamStateCallback
        ) {
          if (this.droneNum != undefined) {
            this.slamStateCallback(data, this.droneNum);
          } else {
            this.slamStateCallback(data);
          }
        } else if (
          topic == `/from/${this.module.droneSerialNumber}/STORAGE_STATE` &&
          this.storageCallback
        ) {
          this.storageCallback(data, this.droneNum);
        } else if (
          topic == `/from/${this.module.droneSerialNumber}/MAP_SAVE_COMPLETE` &&
          this.mapSaveCompleteCallback
        ) {
          this.mapSaveCompleteCallback();
        }
        //       else if(topic == `/from/${this.client_id}/GIMBAL_PITCH` && this.gimbal_pitch_callback){
        //         this.gimbal_pitch_callback(data, this.inspection_drone_num);
        //       }
        else if (
          topic == `/from/${this.module.droneSerialNumber}/DJI_CONNECTION` &&
          this.djiConnectionCallback
        ) {
          this.djiConnectionCallback(data, this.droneNum);
        } else if (
          topic == `/from/${this.module.droneSerialNumber}/DJI_INIT_POSE` &&
          this.djiModulePoseStopCallback
        ) {
          this.djiModulePoseStopCallback(data, this.droneNum);
        } else if (
          topic == `/from/${this.module.droneSerialNumber}/WAYPOINT` &&
          this.waypointCallback
        ) {
          this.waypointCallback(data, this.droneNum);
        } else if (
          topic == `/from/${this.module.droneSerialNumber}/WAYPOINT_PATH` &&
          this.tempWaypointCallback
        ) {
          this.tempWaypointCallback(data, this.droneNum);
        } else if (
          topic == `/from/${this.module.droneSerialNumber}/RESPONSE_DIRECTORY` &&
          this.directoryCallback
        ) {
          this.directoryCallback(data);
        } else if (
          topic == `/from/${this.module.droneSerialNumber}/RESPONSE_ERASE` &&
          this.eraseCallback
        ) {
          this.eraseCallback(data);
        } else if (
          topic == `/from/${this.module.droneSerialNumber}/RESPONSE_COPY` &&
          this.copyCallback
        ) {
          this.copyCallback(data);
        } else if (
          topic == `/from/${this.module.droneSerialNumber}/RESPONSE_USB` &&
          this.usbCallback
        ) {
          this.usbCallback(data);
        } else if (
          topic == `/from/${this.module.droneSerialNumber}/MISSION_STOP` &&
          this.stopCallback
        ) {
          this.stopCallback(this.droneNum);
        } else if (
          topic == `/from/${this.module.droneSerialNumber}/DATA_TRANSFER` &&
          this.dataTransferCallback
        ) {
          this.dataTransferCallback(data);
        } else if (
          topic == `/from/${this.module.droneSerialNumber}/CAMERA_SHOOT_POSE` &&
          this.cameraShootPoseCallback
        ) {
          this.cameraShootPoseCallback(data);
        } else if (
          topic == `/from/${this.module.droneSerialNumber}/ERROR` &&
          this.errorMessageCallback
        ) {
          if (this.droneNum != undefined) {
            this.errorMessageCallback(data, this.droneNum);
          } else {
            this.errorMessageCallback(data);
          }
        } else if (
          topic == `/from/${this.module.droneSerialNumber}/WARNING` &&
          this.warningMessageCallback
        ) {
          if (this.droneNum != undefined) {
            this.warningMessageCallback(data, this.droneNum);
          } else {
            this.warningMessageCallback(data);
          }
        } else if (
          topic == `/from/${this.module.droneSerialNumber}/INITIALIZATION_LIST` &&
          this.initializationListCallback
        ) {
          this.initializationListCallback(data);
        }
      } catch (error) {
        console.error('An error occurred:', error);
      }
    });
  }
}

export default DroneMonitor;
