import Waypoint from '@/model/Waypoint.js';
import Shape from '@/model/Shape.js';
import CameraMission from '@/model/CameraMission';
import API from '@/shared/constant/api';

import EventEmitter from 'events';

function isJSON(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

class WebSocketHandler extends EventEmitter {
  constructor(user, waypointList, groupList) {
    super();
    this.user = user;
    this.ws = null;
    this.messageParsing = this.messageParsing.bind(this);

    this.initialize();

    this.waypointList = waypointList;
    this.groupList = groupList;
    this.currentDroneId = 0;
    this.lowerHeight = 0;
    this.upperHeight = 0;
  }

  initialize() {
    this.ws = new WebSocket(`${API.WEBSOCKET_ORIGIN}/ws/maps/${this.user.id}`);

    this.ws.onopen = (event) => {
      console.log('WebSocket connection opened:', event);

      setInterval(() => {
        if (this.ws.readyState === WebSocket.OPEN) {
          this.ws.send('ping');
        }
      }, 30000);
    };

    window.addEventListener('beforeunload', () => {
      this.programClose();
    });

    this.ws.onmessage = this.messageParsing;

    this.ws.onerror = (event) => {
      console.error('WebSocket error:', event);
    };

    this.ws.onclose = (event) => {
      if (event.wasClean) {
        console.log(
          `연결이 정상적으로 종료되었습니다. 코드: ${event.code}, 이유: ${event.reason}`
        );
      } else {
        console.log('연결이 비정상적으로 종료되었습니다');
      }
    };
  }

  processData(data) {
    return new Promise((resolve) => {
      setTimeout(() => {
        const processedData = JSON.parse(data); // 데이터 처리
        resolve(processedData); // 처리된 데이터 반환
      }, 1000);
    });
  }

  async messageParsing(event) {
    if (!isJSON(event.data)) return;
    let receivedData = JSON.parse(event.data);
    if (receivedData.failed == 'falied') {
      this.emit('waypointNull');
      return;
    }
    if (!Object.prototype.hasOwnProperty.call(receivedData, 'output_type')) {
      console.log("'output_type' does not exist");
      // this.emit('outputTypeNull');
      return;
    }

    let receivedFitType = receivedData.output_type.type;

    if (receivedFitType == 'coll_check') {
      for (let i = 0; i < this.waypointList[this.currentDroneId].length; i++) {
        this.waypointList[this.currentDroneId][i].setChecked(false);
      }
      for (let i = 0; i < this.groupList[this.currentDroneId].length; i++) {
        this.groupList[this.currentDroneId][i].setChecked(false);
      }
      let collidedWpIdxs = receivedData.coll_idx;
      if (!collidedWpIdxs) {
        this.emit('noCollision');
        return;
      }
      collidedWpIdxs.forEach((collidedWpIdx) => {
        const { idx } = collidedWpIdx;
        this.waypointList[this.currentDroneId][idx].checked = true;
      });

      this.emit('setCollidedWP');
    } else {
      if (!receivedData.waypoints || receivedData.waypoints.length === 0) {
        this.emit('waypointNull');
        return;
      }

      if (receivedData.is_new) {
        if (receivedFitType == 'line') {
          const groupInfo = new Shape(
            this.groupList[this.currentDroneId].length + 1,
            receivedFitType,
            this.lineParams.fromWall,
            0
          );
          groupInfo.setHeighParams(
            this.lineParams.heightInterval,
            this.lowerHeight,
            this.upperHeight
          );
          groupInfo.setLineInfo(this.lineParams.isAuto, this.lineParams.direction);
          this.groupList[this.currentDroneId].push(groupInfo);
        } else if (receivedFitType == 'underside') {
          const groupInfo = new Shape(
            this.groupList[this.currentDroneId].length + 1,
            receivedFitType,
            this.undersideParams.fromWall,
            this.undersideParams.interval
          );
          groupInfo.setHeighParams(0, this.lowerHeight, this.upperHeight);
          groupInfo.setBottomInfo(
            this.undersideParams.isAuto,
            this.undersideParams.direction
          );
          this.groupList[this.currentDroneId].push(groupInfo);
        } else if (receivedFitType == 'circle') {
          let receivedCoeffs = receivedData.coeffs;

          let circleCoeffs = [];
          receivedCoeffs.forEach((coeff) => {
            const { x, y, radius, lower, upper } = coeff;
            let circleCoeff = {
              x: x,
              y: y,
              radius: radius,
              lower: lower,
              upper: upper,
            };
            circleCoeffs.push(circleCoeff);
          });

          const groupInfo = new Shape(
            this.groupList[this.currentDroneId].length + 1,
            receivedFitType,
            this.circleParams.fromWall,
            this.circleParams.interval
          );
          groupInfo.setHeighParams(0, this.lowerHeight, this.upperHeight);
          groupInfo.setCircleInfo(
            this.circleParams.direction,
            this.circleParams.startAngle,
            circleCoeffs
          );
          this.groupList[this.currentDroneId].push(groupInfo);
        } else if (receivedFitType == 'rectangle') {
          let receivedCoeffs = receivedData.coeffs;
          let rectCoeffs = [];
          receivedCoeffs.forEach((coeff) => {
            const {
              point1_x,
              point1_y,
              point2_x,
              point2_y,
              point3_x,
              point3_y,
              point4_x,
              point4_y,
              fit_point1_x,
              fit_point1_y,
              fit_point2_x,
              fit_point2_y,
              fit_point3_x,
              fit_point3_y,
              fit_point4_x,
              fit_point4_y,
              rot,
            } = coeff;
            let rectCoeff = {
              point1_x: point1_x,
              point1_y: point1_y,
              point2_x: point2_x,
              point2_y: point2_y,
              point3_x: point3_x,
              point3_y: point3_y,
              point4_x: point4_x,
              point4_y: point4_y,
              fit_point1_x: fit_point1_x,
              fit_point1_y: fit_point1_y,
              fit_point2_x: fit_point2_x,
              fit_point2_y: fit_point2_y,
              fit_point3_x: fit_point3_x,
              fit_point3_y: fit_point3_y,
              fit_point4_x: fit_point4_x,
              fit_point4_y: fit_point4_y,
              rot: rot,
            };

            rectCoeffs.push(rectCoeff);
          });

          const groupInfo = new Shape(
            this.groupList[this.currentDroneId].length + 1,
            receivedFitType,
            this.rectParams.fromWall,
            this.rectParams.interval
          );
          groupInfo.setHeighParams(0, this.lowerHeight, this.upperHeight);
          groupInfo.setRectInfo(this.rectParams.direction, rectCoeffs);
          this.groupList[this.currentDroneId].push(groupInfo);
        } else if (receivedFitType == 'face') {
          const groupInfo = new Shape(
            this.groupList[this.currentDroneId].length + 1,
            receivedFitType,
            this.faceParams.fromWall,
            0
          );
          groupInfo.setHeighParams(0, 0, 0);
          groupInfo.setFaceInfo(this.faceParams.isAuto, this.faceParams.direction);
          this.groupList[this.currentDroneId].push(groupInfo);
        }
      }
      let receivedDataWaypoints = receivedData.waypoints;
      if (receivedData.is_new) {
        receivedDataWaypoints = receivedDataWaypoints.slice(1);
      }
      let upperGimbalUp = true;
      let lowerGimbalUp = false;
      let counter = 0;
      receivedDataWaypoints.forEach((waypoint) => {
        let shouldCapture = true;
        const { x, y, yaw, z } = waypoint; // Destructuring the waypoint object for its properties
        let wpRotation = (yaw / 180.0) * Math.PI;
        let gimbalPitchArray;
        let pitch;
        if (receivedFitType == 'circle' || receivedFitType == 'rectangle') {
          let gimbalControl = true;

          if (receivedFitType == 'circle') {
            gimbalControl = this.circleParams.gimbalControl;
          } else if (receivedFitType == 'rectangle') {
            gimbalControl = this.rectParams.gimbalControl;
          }

          if (!gimbalControl) {
            // 상하부 촬영 안한다
            // pitch = 0;
            pitch =
              receivedFitType === 'circle'
                ? this.circleParams.gimbalPitch
                : this.rectParams.gimbalPitch;
            gimbalPitchArray =
              receivedFitType === 'circle'
                ? this.circleParams.gimbalPitch
                : this.rectParams.gimbalPitch;
            upperGimbalUp = false;
          } else {
            // 상하부 촬영 한다
            if (z == this.upperHeight && z == this.lowerHeight) {
              // 최대 최저 촬영 높이가 같을때
              if (upperGimbalUp) {
                // 아래 촬영

                pitch = 0;
                // gimbalPitchArray = [-90, -60, -30, 0];
                gimbalPitchArray =
                  receivedFitType === 'circle'
                    ? [-this.circleParams.gimbalPitch, this.circleParams.gimbalPitch]
                    : [-this.rectParams.gimbalPitch, this.rectParams.gimbalPitch];
                upperGimbalUp = false;
              } else {
                // 위에 촬영

                pitch = 0;
                // gimbalPitchArray = [90, 60, 30, 0];
                gimbalPitchArray =
                  receivedFitType === 'circle'
                    ? [this.circleParams.gimbalPitch, -this.circleParams.gimbalPitch]
                    : [this.rectParams.gimbalPitch, -this.rectParams.gimbalPitch];
                upperGimbalUp = true;
              }
            } else if (z == this.upperHeight) {
              // 최고 높이에 도착했을 때 위에 촬영
              if (upperGimbalUp) {
                pitch = 0;
                if (receivedData.is_new && counter == 0) {
                  // 1
                  // 첫 웨이포인트 각도
                  // 무조건 아래로 내려가는 촬영부터 한다.
                  // gimbalPitchArray = [90, 60, 30, 0];
                  gimbalPitchArray =
                    receivedFitType === 'circle'
                      ? [this.circleParams.gimbalPitch, 0]
                      : [this.rectParams.gimbalPitch, 0];
                  upperGimbalUp = true;
                } else {
                  // 그 외
                  // 최고 높이에서 위에 촬영
                  // 4, 8, 12
                  gimbalPitchArray =
                    receivedFitType === 'circle'
                      ? [0, this.circleParams.gimbalPitch]
                      : [0, this.rectParams.gimbalPitch];
                  upperGimbalUp = false;
                }
              } else {
                // 5, 9, 13
                // 내려갈 때, 기둥 촬영해야하니까 0으로 맞춘다
                // pitch = 90;
                pitch =
                  receivedFitType === 'circle'
                    ? this.circleParams.gimbalPitch
                    : this.rectParams.gimbalPitch;
                gimbalPitchArray =
                  receivedFitType === 'circle'
                    ? [this.circleParams.gimbalPitch, 0]
                    : [this.rectParams.gimbalPitch, 0];
                upperGimbalUp = true;
              }
            } else {
              // lowerHeight 하부 촬영
              if (lowerGimbalUp) {
                // 위로 올라갈 때, 기둥 촬영해야하니까 0으로 맞춘다
                // 3, 7, 11
                // pitch = -90;
                pitch =
                  receivedFitType === 'circle'
                    ? -this.circleParams.gimbalPitch
                    : -this.rectParams.gimbalPitch;
                gimbalPitchArray =
                  receivedFitType === 'circle'
                    ? [-this.circleParams.gimbalPitch, 0]
                    : [-this.rectParams.gimbalPitch, 0];
                lowerGimbalUp = false;
              } else {
                // 아래 촬영
                // 2, 6, 10
                pitch = 0;
                gimbalPitchArray =
                  receivedFitType === 'circle'
                    ? [0, -this.circleParams.gimbalPitch]
                    : [0, -this.rectParams.gimbalPitch];
                lowerGimbalUp = true;
              }
            }
          }
        } else if (receivedFitType == 'underside') {
          pitch = this.undersideParams.gimbalPitch;
          gimbalPitchArray = this.undersideParams.gimbalPitch;
          upperGimbalUp = false;
        } else if (receivedFitType == 'line') {
          pitch = this.lineParams.gimbalPitch;
          gimbalPitchArray = this.lineParams.gimbalPitch;
          upperGimbalUp = false;
        } else if (receivedFitType == 'face') {
          pitch = 0;
          gimbalPitchArray = this.faceParams.gimbalPitch;
        } else {
          shouldCapture = false;
          pitch = 0;
        }
        let tempCameraMission = new CameraMission({
          gimbalPitch: gimbalPitchArray,
          shouldCapture: shouldCapture,
        });
        let tempWaypoint = new Waypoint(
          x,
          y,
          z,
          wpRotation,
          pitch,
          receivedFitType,
          false,
          false,
          this.groupList[this.currentDroneId].length,
          tempCameraMission
        );
        this.waypointList[this.currentDroneId].push(tempWaypoint);
        counter++;
      });
      if (receivedData.is_end) {
        this.waypointList[this.currentDroneId][
          this.waypointList[this.currentDroneId].length - 1
        ].mission.shouldCapture = false;
        this.emit('parsingCompleted');
      }
    }
  }

  setDroneId(droneId) {
    this.currentDroneId = droneId;
  }

  setHeightRange(lower, upper) {
    this.lowerHeight = lower;
    this.upperHeight = upper;
  }

  setLineParams(params) {
    this.lineParams = params;
  }

  setLineData(linePoints, height, prevPoint) {
    let dataToSend = {
      fit_type: { type: 'line' },
      prev_waypoint: { x: prevPoint.x, y: prevPoint.y },
      line_points: linePoints,
      parameters: {
        direction: this.lineParams.direction,
        auto: this.lineParams.isAuto,
        from_wall: this.lineParams.fromWall,
        height: this.lineParams.height,
        height_interval: this.lineParams.heightInterval,
      },
      height_range: {
        lower: parseFloat(this.lowerHeight),
        upper: parseFloat(this.upperHeight),
        fit_height: height,
      },
    };

    return dataToSend;
  }

  setUndersideParams(params) {
    this.undersideParams = params;
  }

  setUndersideDataManual(linePoints, height) {
    let prevPoint = this.getPrevPoint();
    let tempUndersideFirst, tempUndersideSecond;

    tempUndersideFirst = linePoints.map((vector) => {
      return {
        x: vector.x,
        y: -vector.z,
      };
    });
    tempUndersideSecond = linePoints.map((vector) => {
      return {
        x: vector.x,
        y: -vector.z,
      };
    });

    let longDirection = false;
    if (this.undersideParams.direction == 'short') longDirection = true;

    let dataToSend = {
      fit_type: { type: 'underside' },
      prev_waypoint: { x: prevPoint.x, y: prevPoint.y },
      first_points: tempUndersideFirst,
      second_points: tempUndersideSecond,
      parameters: {
        whole: longDirection,
        auto: this.undersideParams.isAuto,
        interval: this.undersideParams.interval,
        from_wall: this.undersideParams.fromWall,
        drone_dim_x: this.undersideParams.droneDimX,
        drone_dim_y: this.undersideParams.droneDimY,
        drone_dim_z: this.undersideParams.droneDimZ,
      },
      height_range: {
        lower: parseFloat(this.lowerHeight),
        upper: parseFloat(this.upperHeight),
        fit_height: height,
      },
    };

    return dataToSend;
  }

  setUndersideDataAuto(undersidePointsFirst, undersidePointsSecond, prevPoint, height) {
    let tempBottomFirst, tempBottomSecond;

    tempBottomFirst = undersidePointsFirst.map((vector) => {
      return {
        x: vector.x,
        y: -vector.z,
      };
    });
    tempBottomSecond = undersidePointsSecond.map((vector) => {
      return {
        x: vector.x,
        y: -vector.z,
      };
    });

    let longDirection = false;
    if (this.undersideParams.direction == 'short') longDirection = true;

    let dataToSend = {
      fit_type: { type: 'underside' },
      prev_waypoint: { x: prevPoint.x, y: prevPoint.y },
      first_points: tempBottomFirst,
      second_points: tempBottomSecond,
      parameters: {
        whole: longDirection,
        auto: this.undersideParams.isAuto,
        interval: this.undersideParams.interval,
        from_wall: this.undersideParams.fromWall,
        drone_dim_x: this.undersideParams.droneDimX,
        drone_dim_y: this.undersideParams.droneDimY,
        drone_dim_z: this.undersideParams.droneDimZ,
      },
      height_range: {
        lower: parseFloat(this.lowerHeight),
        upper: parseFloat(this.upperHeight),
        fit_height: height,
      },
    };

    return dataToSend;
  }

  setCircleParams(params) {
    this.circleParams = params;
  }

  sendWPData(currentDroneId, droneDim) {
    let sendStep = Math.floor(this.waypointList[currentDroneId].length / 20);
    for (let i = 0; i <= sendStep; i++) {
      let tempWaypoints = [];
      for (let j = 0; j < 20; j++) {
        if (i * 20 + j == this.waypointList[currentDroneId].length) break;
        tempWaypoints.push(this.waypointList[currentDroneId][i * 20 + j]);
      }
      let tempWaypointsData = tempWaypoints.map((vector) => {
        return {
          x: vector.x,
          y: vector.y,
          z: vector.z,
        };
      });
      let isStart = false;
      let isEnd = false;
      if (i === 0) isStart = true;
      if (i === sendStep) isEnd = true;

      let dataToSend;
      dataToSend = {
        fit_type: { type: 'coll_check' },
        waypoints: tempWaypointsData,
        drone_dim: {
          drone_dim_x: droneDim.x,
          drone_dim_y: droneDim.y,
          drone_dim_z: droneDim.z,
        },
        is_start: isStart,
        is_end: isEnd,
      };
      this.send(dataToSend);
    }
  }

  send(dataJson) {
    if (this.ws.readyState === WebSocket.OPEN) {
      this.ws.send(JSON.stringify(dataJson));
    } else {
      console.error('WebSocket connection is not open. Unable to send data.');
    }
  }

  setCircleData(prevPoint, height, startPoint, endPoint) {
    let inward = true;
    if (this.circleParams.direction == 'outward') inward = false;

    let dataToSend = {
      fit_type: { type: 'circle' },
      prev_waypoint: { x: prevPoint.x, y: prevPoint.y },
      start_point: { x: startPoint.x, y: startPoint.y },
      end_point: { x: endPoint.x, y: endPoint.y },
      parameters: {
        inward: inward,
        from_wall: this.circleParams.fromWall,
        interval: this.circleParams.interval,
        start_angle: this.circleParams.startAngle,
      },
      height_range: {
        lower: parseFloat(this.lowerHeight),
        upper: parseFloat(this.upperHeight),
        fit_height: height,
      },
    };

    return dataToSend;
  }

  setRectParams(params) {
    this.rectParams = params;
  }

  setRectData(prevPoint, height, startPoint, endPoint) {
    let inward = true;
    if (this.rectParams.direction == 'outward') inward = false;

    let dataToSend = {
      fit_type: { type: 'rect' },
      prev_waypoint: { x: prevPoint.x, y: prevPoint.y },
      start_point: { x: startPoint.x, y: startPoint.y },
      end_point: { x: endPoint.x, y: endPoint.y },
      parameters: {
        inward: inward,
        interval: this.rectParams.interval,
        from_wall: this.rectParams.fromWall,
      },
      height_range: {
        lower: parseFloat(this.lowerHeight),
        upper: parseFloat(this.upperHeight),
        fit_height: height,
      },
    };

    return dataToSend;
  }

  setFaceParams(params) {
    this.faceParams = params;
  }

  setFaceDataAuto(facePointsFirst, facePointsSecond, prevPoint) {
    let tempBottomFirst, tempBottomSecond;

    tempBottomFirst = facePointsFirst.map((vector) => {
      return {
        x: vector.x,
        y: -vector.z,
        z: vector.y,
      };
    });
    tempBottomSecond = facePointsSecond.map((vector) => {
      return {
        x: vector.x,
        y: -vector.z,
        z: vector.y,
      };
    });

    let dataToSend = {
      fit_type: { type: 'face' },
      prev_waypoint: { x: prevPoint.x, y: prevPoint.y },
      first_points: tempBottomFirst,
      second_points: tempBottomSecond,
      parameters: {
        height_interval: this.faceParams.heightInterval,
        from_wall: this.faceParams.fromWall,
        direction: this.faceParams.direction,
        auto: this.faceParams.isAuto,
      },
    };

    return dataToSend;
  }

  programClose() {
    if (this.ws.readyState === WebSocket.OPEN) {
      this.ws.send('closeFitArea');
      this.ws.close();
    } else {
      console.error('WebSocket connection is not open. Unable to send data.');
    }
  }
  // close(){
  //   if(this.ws){
  //     this.ws.close();
  //   }
  // }
}
export default WebSocketHandler;
