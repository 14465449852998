<template>
  <div class="report_map_list_container">
    <div class="report_map_list_name">{{ $t("term.mapList") }}</div>
    <div class="report_map_item_list">
      <div
        v-for="(map, mapIndex) in filteredMapList"
        :key="mapIndex"
        class="report_map_item"
        @click="this.$emit('selectMap', map)"
        :class="{
          active: selectedFacilityMap
            ? map.id == selectedFacilityMap.id
            : false,
        }"
      >
        <div class="report_map_item_name">{{ map.createdDatetime }}</div>
        <div
          class="report_expand_menu_button button"
          :class="{ on: map == expandMap }"
          @click.stop="expandMapMenu(map)"
        ></div>
      </div>
    </div>
  </div>
  <div class="report_map_expand_menu_modal" v-if="expandMap" :style="{ top: expandMapMenuOffsetTop + 80 + 'px' }">
    <div class="report_map_button button" @click="downloadMap(expandMap)">
      {{ $t("button.download") }}
    </div>
    <div class="report_map_button button" @click="goInspection(expandMap.id)">
      {{ $t("term.inspection") }}
    </div>
    <!-- <div class="report_map_button button" @click="goMeasure(expandMap.id)">
      측량
    </div>
    <div class="report_map_button button" @click="goPCMeasure2D(expandMap.id)">
      PCD 측량 2D
    </div>
    <div class="report_map_button button" @click="goPCMeasure3D(expandMap.id)">
      PCD 측량 3D
    </div> -->
    <div class="report_map_button button" @click.stop="deleteMap(expandMap.id)">
      {{ $t("button.delete") }}
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState(["selectedFacility", "selectedFacilityMap", "user"]),
  },
  emits: ["selectMap", "deleteMap", "expandAlbumMenu"],
  props: {
    filteredMapList: Array,
  },
  data() {
    return {
      expandMap: null,
      expandMapMenuOffsetTop: 0,
    };
  },
  methods: {
    expandMapMenu(map) {
      if (map == this.expandMap) {
        this.expandMap = null;
      } else {
        this.$emit("expandAlbumMenu");
        this.expandMap = map;
        let clickedItem = event.currentTarget.closest('.report_map_item');
        if (clickedItem) {
          this.expandMapMenuOffsetTop = clickedItem.offsetTop;
        }
      }
    },
    downloadMap(map) {
      this.$store.commit("setDownloading", true);
      let fileUrl = map.url;
      let link = document.createElement("a");
      link.href = fileUrl;
      link.setAttribute("download", "map.pcd");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      this.$store.commit("setDownloading", false);
    },
    goInspection(mapId) {
      this.$router.push({
        name: "inspection",
        params: { facilityId: this.$route.params.facilityId, mapId: mapId },
      });
    },
    // goMeasure(mapId) {
    //   this.$router.push({
    //     name: "measure",
    //     params: { facilityId: this.$route.params.facilityId, mapId: mapId },
    //   });
    // },
    // goPCMeasure2D(mapId) {
    //   this.$router.push({
    //     name: "pc-measure-2d",
    //     params: { facilityId: this.$route.params.facilityId, mapId: mapId },
    //   });
    // },
    // goPCMeasure3D(mapId) {
    //   this.$router.push({
    //     name: "pc-measure-3d",
    //     params: { facilityId: this.$route.params.facilityId, mapId: mapId },
    //   });
    // },
    deleteMap(mapId) {
      if (this.user.role == "normal") {
        this.$store.commit(
          "openAlert",
          this.$t("otherAlert.unauthorizedPleaseContactAdmin")
        );
      } else {
        if (confirm(this.$t("albumAlert.reallyDeleteMap"))) {
          this.expandMap = null;
          this.$emit("deleteMap", mapId);
        } else {
          return false;
        }
      }
    },
  },
};
</script>

<style lang="scss">
.report_map_list_container {
  height: calc((100% - 150px) / 2);
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: transparent;
}
.report_map_list_name {
  display: flex;
  height: calc(100% / 8);
  width: 100%;
  align-items: center;
  color: rgb(255, 255, 255);
  font-size: 16px;
  margin-bottom: 10px;
}
.report_map_item_list {
  display: flex;
  flex-direction: column;
  height: calc(100% / 8 * 7 - 10px);
  width: 100%;
  padding-right: 10px;
  overflow: auto;
}
.report_map_item_list::-webkit-scrollbar {
  width: 4px;
}
/* 스크롤바 트랙 스타일 */
.report_map_item_list::-webkit-scrollbar-track {
  background: transparent;
  border: 1px solid rgba(1, 250, 254, 0.5);
}
/* 스크롤바 손잡이 스타일 */
.report_map_item_list::-webkit-scrollbar-thumb {
  background: rgba(1, 250, 254, 0.8);
  border-radius: 5px;
}
/* 스크롤바 손잡이:hover 스타일 */
.report_map_item_list::-webkit-scrollbar-thumb:hover {
  background: rgba(1, 250, 254, 1);
}

.report_map_item {
  display: flex;
  flex-shrink: 0;
  width: calc(100% - 4px);
  height: 40px;
  align-items: center;
  border-radius: 5px;
  box-shadow: $main_box_shadow;
  color: $main_white;
  font-size: 1.6rem;
  cursor: pointer;
  margin: 2px;
  margin-bottom: 10px;
}
.report_map_item:hover {
  color: $main_color;
  border: 1px solid $main_color;
}
.report_map_item.active {
  color: $main_color;
  border: 1px solid $main_color;
}
.report_map_item_name {
  display: flex;
  width: calc(100% - 45px);
  height: 100%;
  align-items: center;
  padding-left: 10px;
}
.report_map_item_button_wrap {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 50%;
}
.report_map_button {
  @include flexbox;
  width: 100%;
  height: 30px;
  font-size: 1.5rem;
}
.report_expand_menu_button {
  margin: 0px 10px 0px 10px;
  width: 25px;
  height: 25px;
  box-shadow: none;
  background-color: transparent;
  background-image: url("@/../public/images/ellipsis_vertical.svg");
  background-size: 4px, 15px;
}
.report_expand_menu_button:hover {
  background-image: url("@/../public/images/ellipsis_vertical_hover.svg");
  background-size: 4px, 15px;
}
.report_expand_menu_button.on {
  background-image: url("@/../public/images/ellipsis_vertical_hover.svg");
  background-size: 4px, 15px;
  cursor: pointer;
}
.report_map_expand_menu_modal {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  left: calc(100% / 4 + 25px);
  top: 0;
  width: 150px;
  height: 130px;
  background: rgba(0, 0, 0, 0.5);
  padding: 10px;
  border-radius: 5px;
}
@media (min-width: 1250px) {
  .report_map_expand_menu_modal {
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    left: 335px;
    top: 0;
    width: 150px;
    height: 130px;
    background: rgba(0, 0, 0, 0.5);
    padding: 10px;
    border-radius: 5px;
  }
}
</style>
