<template>
  <div class="route_modal_wrap">
    <div class="title_box">
      <h2 v-if="emptyMapUrlCount === 0">
        {{ $t("message.needMappingFirst") }}
      </h2>
      <h2 v-else>{{ $t("message.whatDoYouWant") }}</h2>
      <button class="close_button" @click="$emit('closeModal')"></button>
    </div>
    <DefaultSelectBox
      v-if="emptyMapUrlCount > 0"
      :className="'full focus'"
      :selectedName="formatDateTime(selectedMapName)"
      :defaultName="formatDateTime(reversedMaps[0].createdDatetime)"
      :isOptionOpen="isMapOpen"
      :optionTag="$t('term.new')"
      :needOptionTag="selectedMapName === reversedMaps[0].createdDatetime"
      :optionTagClassName="'state_badge blue'"
      @update:isOptionOpen="isMapOpen = $event"
      @controlOptionToggle="mapOptionToggle"
      :style="'margin-bottom: 18px'"
    >
      <ul>
        <template v-if="reversedMaps[0].url !== ''">
          <li
            v-for="(map, index) in reversedMaps"
            :key="map.id"
            @click="mapOptionClick(map)"
            :class="
              map.createdDatetime === this.selectedMapName ? 'active' : ''
            "
          >
            <button class="option_text" type="button">
              <p>{{ formatDateTime(map.createdDatetime) }}</p>
              <div v-if="index === 0" class="state_badge blue">
                {{ $t("term.new") }}
              </div>
            </button>
          </li>
        </template>
      </ul>
    </DefaultSelectBox>
    <div class="route_list">
      <button type="button" @click="mappingRoute">
        {{ $t("term.mapping") }}
      </button>
      <!-- <button type="button" @click="newMapping" v-else>새로운 맵 생성</button> -->
      <button
        type="button"
        :disabled="emptyMapUrlCount === 0"
        @click="$emit('handleRouteInspection')"
      >
        {{ $t("term.inspection") }}
      </button>
      <button
        type="button"
        :disabled="emptyMapUrlCount === 0"
        @click="$emit('handleRouteReport')"
      >
        {{ $t("term.report") }}
      </button>
    </div>
  </div>
</template>
<script>
import DefaultSelectBox from "@/component/select/DefaultSelectBox.vue";
import { filterEmptyMapUrl } from "@/shared/util/filterEmptyMapUrl";
import { formatDateTime } from "@/shared/util/formatDateTime";
import { mapState } from "vuex";

export default {
  name: "RouteModalContent",
  emits: [
    "handleMappingRoute",
    "handleSaveMapInfo",
    "handleRouteInspection",
    "handleRouteReport",
    "closeModal",
  ],
  computed: {
    ...mapState(["selectedFacility"]),
    reversedMaps() {
      // 원본 배열 수정하지 않고 거꾸로 출력
      if (this.selectedFacility?.mapList) {
        const reversedArray = this.selectedFacility.mapList.slice().reverse();

        const newArray = reversedArray.filter((map) => map.url !== "");
        this.$store.commit("setSelectedFacilityMap", newArray[0]);
        return newArray;
      } else {
        return null;
      }
    },
  },
  components: {
    DefaultSelectBox,
  },
  data() {
    return {
      isMapOpen: false,
      selectedMapName: null,
      emptyMapUrlCount: null,
    };
  },
  methods: {
    formatDateTime,
    filterEmptyMapUrl,
    /**
     * 맵 옵션 토글
     */
    mapOptionToggle() {
      this.isMapOpen = !this.isMapOpen;
    },
    /**
     * 맵 옵션 클릭 동작
     */
    mapOptionClick(selectedMap) {
      this.selectedMapName = selectedMap.createdDatetime;
      this.$emit("handleSaveMapInfo", selectedMap);
      this.isMapOpen = false;
    },
    mappingRoute() {
      let mappingType;
      if (this.selectedFacility.mapList.length == 0) {
        // 맵이 하나도 없고 첫번째 생성일 때
        mappingType = "first";
      } else if (this.selectedFacility.mapList[0].url === "") {
        // url이 비어있는 맵이 하나라도 존재할 때
        mappingType = "exist";
      } else {
        // url이 있는 맵이 있는데 새로 생성하는 것일 때
        mappingType = "new";
      }
      this.$emit("handleMappingRoute", mappingType);
    },
  },
  mounted() {
    this.emptyMapUrlCount = filterEmptyMapUrl(
      this.selectedFacility?.mapList
    ).length;
    if (this.reversedMaps?.length > 0) {
      this.selectedMapName = this.reversedMaps[0].createdDatetime;
    }
  },
};
</script>
<style lang="scss" scoped>
.route_modal_wrap {
  padding: 30px;

  @include mobile {
    max-width: 400px;
    min-width: 300px;
    width: 100%;
  }
}
.title_box {
  @include flexbox(center, space-between, row);
  margin-bottom: 30px;

  h2 {
    font-size: 2.4rem;

    @include mobile {
      font-size: 1.8rem;
    }
  }

  .close_button {
    width: 30px;
    height: 30px;
    background: url("@/../public/images/x_white.svg") no-repeat center;
    background-size: contain;
  }
}

.route_list {
  @include flexbox(center, center, row);
  gap: 12px;

  @include mobile {
    @include flexbox(flex-start, flex-start, column);
  }

  button {
    @include flexbox;
    width: 200px;
    padding: 80px 0;
    font-size: 3rem;
    font-weight: 500;
    font-size: 1.8rem;
    color: $main_white;
    border: 1px solid $black_252530;
    transition: 0.2s;

    @include mobile {
      width: 100%;
      padding: 30px 0;
      font-size: 1.6rem;
    }

    &:hover {
      color: $main_color;
      border: 1px solid $main_color;
    }

    &:disabled {
      color: $black_353542;

      &:hover {
        color: $black_353542;
        border: 1px solid $black_252530;
      }
    }
  }
}
</style>
