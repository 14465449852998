const API = {
  IP: "211.224.129.230",
  SERVER_PORT : "443",
  PORT: "10010",
  MQTT_PORT:"10021",
  HOST:"www.sirius-ex.co.kr",
};

API.WEBSOCKET_ORIGIN = `wss://${API.HOST}:${API.PORT}`;
API.HTTP_ORIGIN = `https://${API.HOST}:${API.PORT}`;
API.MQTT_ORIGIN = `wss://${API.HOST}:${API.MQTT_PORT}`;
API.SERVER_WEBSOCKET_ORIGIN = `wss://${API.HOST}`

export default API;
