<template>
  <div class="user_info_box">
    <div class="select_lang">
      <CustomSelectBox
        :className="`focus small text_center center`"
        :isOptionOpen="isLangSelectBoxOpened"
        :defaultName="$i18n.locale"
        :selectedName="$i18n.locale"
        @update:isOptionOpen="isLangSelectBoxOpened = $event"
        @controlOptionToggle="controlOptionToggle"
      >
        <template v-slot:selectButtonContent>
          <div class="select_lang_button">
            <span class="lang_icon"></span>
            <p>{{ $i18n.locale }}</p>
          </div>
        </template>
        <template v-slot:optionContent>
          <ul class="select_lang_box">
            <li :class="{ active: $i18n.locale === 'KO' }">
              <button
                @click="handleClickLangOption('KO')"
                class="option_text"
                type="button"
              >
                한국어
              </button>
            </li>
            <li :class="{ active: $i18n.locale === 'EN' }">
              <button
                @click="handleClickLangOption('EN')"
                class="option_text"
                type="button"
              >
                English
              </button>
            </li>
            <li :class="{ active: $i18n.locale === 'JP' }">
              <button
                @click="handleClickLangOption('JP')"
                class="option_text"
                type="button"
              >
                日本語
              </button>
            </li>
            <li :class="{ active: $i18n.locale === 'CN' }">
              <button
                @click="handleClickLangOption('CN')"
                class="option_text"
                type="button"
              >
                中文(简体)
              </button>
            </li>
          </ul>
        </template>
      </CustomSelectBox>
    </div>
    <div class="user_info" ref="headerMenuButton">
      <button
        type="button"
        class="user_info_button"
        @click="handleShowHeaderMenu"
      >
        <h3>{{ user.fullName }}</h3>
        <div
          class="profile"
          :class="user.profileImageUrl ? '' : 'no_image'"
          :style="[
            user.profileImageUrl
              ? { backgroundImage: `url(${encodeURI(user.profileImageUrl)})` }
              : null,
          ]"
        ></div>
      </button>
      <ul class="pop_menu" v-if="isMenuShow">
        <li v-if="user.role === 'admin'">
          <button
            type="button"
            class="pop_menu_button user"
            @click="handleRouteToUserPage"
          >
            <p>{{ $t("term.userManagement") }}</p>
          </button>
        </li>
        <li>
          <button
            type="button"
            class="pop_menu_button mypage"
            @click="handleOpenInfoEdit"
          >
            <p>{{ $t("term.myInfo") }}</p>
          </button>
        </li>
        <li>
          <button
            type="button"
            class="pop_menu_button log"
            @click="handleOpenDroneLog"
          >
            <p>{{ $t("term.flightLog") }}</p>
          </button>
        </li>
        <li v-if="user.role === 'admin' || user.role === 'manager'">
          <button
            type="button"
            class="pop_menu_button data_management"
            @click="handleDataManagement"
          >
            <p>{{ $t("term.dataManagement") }}</p>
          </button>
        </li>
        <li>
          <button
            type="button"
            class="pop_menu_button logout"
            @click="handleLogOut"
          >
            <p>{{ $t("term.logout") }}</p>
          </button>
        </li>
      </ul>
    </div>
  </div>
  <CustomModalFrame
    :type="modalName === 'myPage' ? null : 'hasTitle'"
    :title="
      modalName === 'drone'
        ? this.$t('term.flightLog')
        : this.$t('term.dataManagement')
    "
    v-if="isModalOpen"
    @closeModal="handleCloseCustomFrame"
  >
    <UserEditFormContent
      v-if="modalName === 'myPage'"
      :editMode="'myInfo'"
      :uploadedImageUrl="uploadedImageUrl"
      :selectedUserInfo="selectedUserInfo"
      :isDeleted="isDeleted"
      @handleCloseCustomFrame="handleCloseCustomFrame"
      @handleUploadFile="handleUploadFile($event)"
      @handleEditUser="handleEditUser($event)"
      @handleRemoveProfileImage="handleRemoveProfileImage"
    />
    <DroneLogDownloadContent v-if="modalName === 'drone'" />
    <ModuleDataMangeMent v-if="modalName === 'data'" />
  </CustomModalFrame>
</template>
<script>
import { mapState } from "vuex";
import * as RESTAPI from "@/shared/communication/RESTAPI";

import UserEditFormContent from "@/page/user/component/UserEditFormContent.vue";
import { addOutsideClickHandler } from "@/shared/util/handleOutsideClickHandler";
import CustomModalFrame from "@/component/modal/custom/CustomModalFrame.vue";
import DroneLogDownloadContent from "@/component/drone-log/DroneLogDownloadContent.vue";
import CustomSelectBox from "@/component/select/CustomSelectBox.vue";
import ModuleDataMangeMent from "@/component/dataManagement/DataManagement.vue";
// import { paginateArray } from "@/shared/util/paginateArray";

export default {
  name: "HeaderUserBox",
  computed: {
    ...mapState(["user"]),
  },
  components: {
    CustomModalFrame,
    UserEditFormContent,
    DroneLogDownloadContent,
    CustomSelectBox,
    ModuleDataMangeMent,
  },
  data() {
    return {
      isMenuShow: false,
      isModalOpen: false,
      modalName: null,
      uploadedImageUrl: null,
      uploadedImageFile: null,
      isDeleted: false,
      selectedUserInfo: null,
      isLangSelectBoxOpened: false,
    };
  },
  methods: {
    handleLogOut() {
      if (confirm(this.$t("otherAlert.wannaLogout"))) {
        this.$store.dispatch("logout", true);
      } else {
        return false;
      }
    },
    handleShowHeaderMenu() {
      this.isMenuShow = !this.isMenuShow;
    },
    handleRouteToUserPage() {
      this.$router.push("/user");
    },
    /**
     * 모달 닫기
     */
    handleCloseCustomFrame() {
      this.isModalOpen = false;
      this.modalName = null;

      this.uploadedImageUrl = null;
      this.uploadedImageFile = null;
      this.isDeleted = false;
    },
    /**
     * 내 정보 수정 모달 띄우기
     */
    async handleOpenInfoEdit() {
      this.$store.commit("setIsLoading", true);
      try {
        let userData = await RESTAPI.getUser(this.user.id);

        if (userData.success) {
          this.$store.commit("setIsLoading", false);
          let userDataResult = userData.result;
          this.selectedUserInfo = userDataResult;
          this.$store.dispatch("login", userDataResult);
          this.isModalOpen = true;
          this.modalName = "myPage";
        } else {
          this.$store.commit("setIsLoading", false);
          this.handleCloseCustomFrame();
          return this.$store.commit(
            "openAlert",
            `${this.t("otherAlert.failedToLoadMyInfo")}\n${this.t(
              "otherAlert.pleaseReloadOrRetry"
            )}`
          );
        }
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.handleCloseCustomFrame();
        console.log(error);
        return this.$store.commit(
          "openAlert",
          `${this.t("otherAlert.failedToLoadMyInfo")}\n${this.t(
            "otherAlert.pleaseReloadOrRetry"
          )}`
        );
      }
    },
    /**
     * 프로필 이미지 파일 업로드
     */
    handleUploadFile({ file, inputElement }) {
      // 동일한 파일을 업로드할 때, 디텍되지 않는 문제를 해결하기 위함.
      inputElement.value = "";

      let image = window.URL.createObjectURL(file);
      this.uploadedImageFile = file;
      this.uploadedImageUrl = image;
      this.isDeleted = false;
    },
    /**
     * 유저 프로필 이미지 삭제
     */
    handleRemoveProfileImage() {
      this.uploadedImageUrl = null;
      this.uploadedImageFile = "";
      this.isDeleted = true;
    },
    /**
     * 비행 로그 다운로드 모달 열기
     */
    handleOpenDroneLog() {
      this.isModalOpen = true;
      this.modalName = "drone";
    },
    handleDataManagement() {
      this.isModalOpen = true;
      this.modalName = "data";
    },
    /**
     * 유저 수정
     */
    async handleEditUser(newUserInfo) {
      this.$store.commit("setIsLoading", true);

      try {
        /**
         * ? : 이미지 파일이 존재한다면 formData로 전송
         */

        if (this.uploadedImageFile || this.isDeleted) {
          const formData = new FormData();

          if (this.uploadedImageFile) {
            // 프로필 이미지 업로드하면 file object
            formData.append("userProfile", this.uploadedImageFile);
          } else if (this.isDeleted) {
            // 프로필 이미지 삭제하면 빈 값
            formData.append(
              "userProfile",
              new File([], "", { type: "text/plain" })
            );
          }
          await RESTAPI.postUserThumbnail(this.user.id, formData);
        }

        /**
         * 이미지 파일을 제외한 데이터 전송
         */
        newUserInfo.affiliation.id = this.user.affiliation.id;
        const editData = await RESTAPI.patchUser(this.user.id, newUserInfo);

        if (editData.success) {
          let userData = await RESTAPI.getUser(this.user.id);
          let userDataResult = userData.result;

          if (userData.success) {
            this.$store.commit("setUser", { newValue: userDataResult });
            sessionStorage.setItem("user", JSON.stringify(userDataResult));

            this.handleCloseCustomFrame();
          }
        }

        if (editData.status === 415) {
          return this.$store.commit(
            "openAlert",
            `${this.$t("otherAlert.ErrorOccurredWithErrorMessage", {
              errorMessage: editData.data.error,
            })}\n${this.$t("otherAlert.pleaseReloadOrRetry")}`
          );
        }
      } catch (error) {
        return this.$store.commit(
          "openAlert",
          `${this.$t("otherAlert.ErrorOccurredWithErrorMessage", {
            errorMessage: error,
          })}\n${this.$t("otherAlert.pleaseReloadOrRetry")}`
        );
      } finally {
        this.$store.commit("setIsLoading", false);
      }
    },
    /**
     * 언어 변경 셀렉트 박스의 옵션을 열고 닫아준다.
     */
    controlOptionToggle() {
      this.isLangSelectBoxOpened = !this.isLangSelectBoxOpened;
    },
    /**
     * 언어 변경 셀렉트 박스 옵션 선택시
     */
    handleClickLangOption(lang) {
      this.$i18n.locale = lang;
      localStorage.setItem("lang", this.$i18n.locale);
      this.controlOptionToggle();
    },
  },
  mounted() {
    // ref 이외의 영역을 클릭했을 때 닫히도록
    this.clickHandlerRemover = addOutsideClickHandler(
      this.$refs.headerMenuButton,
      () => {
        this.isMenuShow = false;
      }
    );
  },
  beforeUnmount() {
    // ref 이외의 영역을 클릭했을 때 닫히도록
    if (this.clickHandlerRemover) {
      this.clickHandlerRemover();
    }
  },
};
</script>
<style lang="scss" scoped>
.user_info_box {
  display: flex;
  @include flexbox(center, flex-start, row);
  gap: 0 8px;

  .select_lang {
    line-height: 1;
    margin-right: 12px;

    .select_lang_button {
      @include flexbox;
      gap: 0 8px;

      .lang_icon {
        width: 14px;
        height: 14px;
        background: url("@/../public/images/globe-light-gray.svg") no-repeat
          center;
        background-size: contain;
      }

      p {
        font-size: 1.2rem;
        color: $gray_a1a1a1;
      }
    }
  }

  .user_info {
    position: relative;

    .user_info_button {
      color: $main_white;
      @include flexbox;
      gap: 12px;

      &:hover {
        h3 {
          color: $main_color;
        }
      }

      h3 {
        font-size: 1.4rem;
        font-weight: 600;
        transition: 0.15s;
      }

      .profile {
        width: 28px;
        height: 28px;
        border-radius: 50px;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;

        &.no_image {
          @include basicProfile;
        }
      }
    }

    .pop_menu {
      position: absolute;
      top: 120%;
      right: 0;
      min-width: 120px;
      max-width: 180px;
      background: $black_26262d;
      border-radius: 4px;
      @include flexbox(center, center, column);

      li {
        padding: 12px 0;
      }
    }

    .pop_menu_button {
      transition: 0.2s;
      color: $main_white;
      font-size: 1.4rem;
      padding-right: 24px;

      @include hoverPointer {
        &:hover {
          color: $main_color;
        }
      }

      p {
        width: 100%;
      }

      &.user {
        background: url("@/../public/images/users_gear_regular.svg") no-repeat
          center right;
        background-size: 19px 19px;

        @include hoverPointer {
          &:hover {
            background: url("@/../public/images/users_gear_regular_main.svg")
              no-repeat center right;
            background-size: 19px 19px;
          }
        }
      }

      &.mypage {
        background: url("@/../public/images/user_pen_regular.svg") no-repeat
          center right;
        background-size: 19px 19px;

        @include hoverPointer {
          &:hover {
            background: url("@/../public/images/user_pen_regulqr_main.svg")
              no-repeat center right;
            background-size: 19px 19px;
          }
        }
      }

      &.log {
        padding-right: 27px;
        background: url("@/../public/images/drone_bold_white.svg") no-repeat
          center right;
        background-size: 22px 22px;

        @include hoverPointer {
          &:hover {
            background: url("@/../public/images/drone_bold_main.svg") no-repeat
              center right;
            background-size: 22px 22px;
          }
        }
      }

      &.logout {
        background: url("@/../public/images/logout_white.svg") no-repeat center
          right;
        background-size: 14px 14px;

        @include hoverPointer {
          &:hover {
            background: url("@/../public/images/logout_main.svg") no-repeat
              center right;
            background-size: 14px 14px;
          }
        }
      }

      &.data_management {
        background: url("@/../public/images/usb_white.svg") no-repeat center
          right;
        background-size: 20px 20px;

        @include hoverPointer {
          &:hover {
            background: url("@/../public/images/usb_main.svg") no-repeat center
              right;
            background-size: 20px 20px;
          }
        }
      }
    }
  }
}
</style>
