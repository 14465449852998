class DroneControl {
  constructor(mqttClient, module, facilityId, facilityName) {
    this.mqttClient = mqttClient;
    this.module = module;
    this.facilityId = facilityId;
    this.facilityName = facilityName;

    setInterval(() => {
      // 1초마다 토픽을 날려서 웹 서버와의 연결 유지 확인
      this.mqttClient.publish(
        `/to/${this.module.droneSerialNumber}/GCS_CONNECTION`,
        `{"connection": true}`
      );
    }, 1000);
  }
  /**
   * 드론에 연결 토픽 날린다.
   */
  moduleHeartBeat() {
    this.mqttClient.publish(
      `/to/${this.module.droneSerialNumber}/HEARTBEAT`,
      `{"time": 10, "droneId" : ${this.module.id}}`
    );
  }
  modulePose(data) {
    this.mqttClient.publish(`/to/${this.module.droneSerialNumber}/MODULE_POSE`, data);
  }
  initialize(drone_type, action, batteryCriteria) {
    this.mqttClient.publish(
      `/to/${this.module.droneSerialNumber}/SIRIUS_INFO`,
      `{"type": "${drone_type}", "action": "${action}", "battery_criteria" : ${batteryCriteria}}`
    );
  }
  arm() {
    this.setModeGuide();
    this.mqttClient.publish(
      `/to/${this.module.droneSerialNumber}/msg_basic_command/TURN_ON`,
      `{"turn_on": true}`
    );
    console.log('Arm');
  }
  disArm() {
    this.mqttClient.publish(
      `/to/${this.module.droneSerialNumber}/msg_basic_command/TURN_ON`,
      `{"turn_on": false}`
    );
    console.log('Disarm');
  }
  takeOff(alt) {
    let regex = /[^0-9.]/g;
    if (alt == '') {
      return '아무런값도 입력하지 않으셨습니다.';
    } else if (regex.test(alt)) {
      return '숫자를 입력하세요.';
    } else if (alt < 1 || 100 < alt) {
      return '1~100 사이의 값을 입력하세요.';
    } else {
      this.mqttClient.publish(
        `/to/${this.module.droneSerialNumber}/msg_basic_command/TAKEOFF`,
        `{"altitude": ${parseFloat(alt)}}`
      );
      console.log(`Take off ${alt}m`);
      return false;
    }
  }
  land() {
    this.mqttClient.publish(
      `/to/${this.module.droneSerialNumber}/msg_basic_command/LAND`,
      `{"set_land": true}`
    );
    console.log('Land');
  } //드론 착륙
  setModeGuide() {
    this.mqttClient.publish(
      `/to/${this.module.droneSerialNumber}/msg_basic_command/SET_MODE`,
      `{"mode": "GUIDED"}`
    );
    console.log('Guided');
  } //드론 모드 가이드로 변경
  setModeLoiter() {
    this.mqttClient.publish(
      `/to/${this.module.droneSerialNumber}/msg_basic_command/SET_MODE`,
      `{"mode": "LOITER"}`
    );
    console.log('Loiter');
  } //드론 모드 로이터로 변경
  returnHome() {
    this.mqttClient.publish(
      `/to/${this.module.droneSerialNumber}/msg_basic_command/SET_MODE`,
      `{"mode": "RTL"}`
    );
    console.log('RTL');
  }
  // RTL 새로 추가된 토픽 (매핑에서도 RTL일 때 이걸 사용할 건지? 지금은 인스펙션에서만 사용)
  setRTL(boolean, collisionInfoList) {
    if (!collisionInfoList) {
      this.mqttClient.publish(
        `/to/${this.module.droneSerialNumber}/RTL`,
        `{"rtl": ${boolean}}`
      );
    } else {
      this.mqttClient.publish(
        `/to/${this.module.droneSerialNumber}/RTL`,
        `{"rtl": ${boolean},"collisionInfoList":${collisionInfoList}}`
      );
    }
    console.log('RTL');
  }
  setRTH(boolean, collisionInfoList) {
    if (!collisionInfoList) {
      this.mqttClient.publish(
        `/to/${this.module.droneSerialNumber}/RTH`,
        `{"rth": ${boolean}}`
      );
    } else {
      this.mqttClient.publish(
        `/to/${this.module.droneSerialNumber}/RTH`,
        `{"rth": ${boolean},"collisionInfoList":${collisionInfoList}}}`
      );
    }
    console.log('RTH');
  }
  pointCloudSave() {
    this.mqttClient.publish(
      `/to/${this.module.droneSerialNumber}/msg_ftp/MAP_SAVE`,
      `{"point_cloud_save": true}`
    );
    console.log('Pointcloud Save');
  }
  reboot() {
    this.mqttClient.publish(
      `/to/${this.module.droneSerialNumber}/REBOOT`,
      `{"reboot": true}`
    );
    console.log('Reboot');
  }
  gpsMode(gps_mode) {
    this.mqttClient.publish(
      `/to/${this.module.droneSerialNumber}/msg_gps_mode_transition/SET_GPS_MODE`,
      `{"gps_mode": ${gps_mode}}`
    );
    console.log(`Gps Mode : ${gps_mode}`);
  }
  maxSpeed(max_speed) {
    this.mqttClient.publish(
      `/to/${this.module.droneSerialNumber}/msg_params/SET_MAX_SPEED`,
      `{"max_speed": "${max_speed}"}`
    );
    console.log(`Max Speed : ${max_speed}`);
  }
  maxYawSpeed(max_yaw_speed) {
    this.mqttClient.publish(
      `/to/${this.module.droneSerialNumber}/msg_params/SET_MAX_YAW_SPEED`,
      `{"max_yaw_speed": "${max_yaw_speed}"}`
    );
    console.log(`Max Yaw Speed : ${max_yaw_speed}`);
  }
  obstacleDistance(obstacle_distance) {
    this.mqttClient.publish(
      `/to/${this.module.droneSerialNumber}/msg_params/SET_OBSTACLE_DISTANCE`,
      `{"obstacle_distance": "${obstacle_distance}"}`
    );
    console.log(`Obstacle Distance : ${obstacle_distance}`);
  }
  waypointSpeed(waypoint_speed) {
    this.mqttClient.publish(
      `/to/${this.module.droneSerialNumber}/msg_params/SET_WAYPOINT_SPEED`,
      `{"waypoint_speed": "${waypoint_speed}"}`
    );
    console.log(`Waypoint Speed : ${waypoint_speed}`);
  }
  waypointYawSpeed(waypoint_yaw_speed) {
    this.mqttClient.publish(
      `/to/${this.module.droneSerialNumber}/msg_params/SET_WAYPOINT_YAW_SPEED`,
      `{"waypoint_yaw_speed": "${waypoint_yaw_speed}"}`
    );
    console.log(`Waypoint Yaw Speed : ${waypoint_yaw_speed}`);
  }
  missionInfo(jsonData) {
    this.mqttClient.publish(
      `/to/${this.module.droneSerialNumber}/MISSION_INFO`,
      `${jsonData}`
    );
    console.log('Mission Publish');
  }
  // missionStart(waypointNumber) {
  //   this.mqttClient.publish(
  //     `/to/${this.module.droneSerialNumber}/msg_waypoints/MISSION_START`,
  //     `{"waypoint_number" : ${waypointNumber}}`
  //   );
  // }
  setWaypoint(poseX, poseY, poseZ, yaw, gimbalPitch, type, collisionInfo) {
    var yawRad = (yaw * Math.PI) / 180;
    var oriZ = Math.sin(yawRad / 2);
    var oriW = Math.cos(yawRad / 2);
    if (collisionInfo && collisionInfo?.length != 0) {
      this.mqttClient.publish(
        `/to/${this.module.droneSerialNumber}/msg_waypoints/SET_WAYPOINT`,
        `{"pose_x": ${poseX}, "pose_y" : ${poseY}, "pose_z" : ${poseZ}, "ori_x" : ${0}, "ori_y" : ${0}, "ori_z" : ${oriZ}, "ori_w" : ${oriW}, "gimbal_pitch" : ${gimbalPitch}, "type" : "${type}", "collisionInfoList" : ${JSON.stringify(
          collisionInfo
        )}}`
      );
    } else {
      this.mqttClient.publish(
        `/to/${this.module.droneSerialNumber}/msg_waypoints/SET_WAYPOINT`,
        `{"pose_x": ${poseX}, "pose_y" : ${poseY}, "pose_z" : ${poseZ}, "ori_x" : ${0}, "ori_y" : ${0}, "ori_z" : ${oriZ}, "ori_w" : ${oriW}, "gimbal_pitch" : ${gimbalPitch}, "type" : "${type}", "collisionInfoList" : null}`
      );
    }
  }
  mission(gimbalPitchArray, shouldCapture) {
    if (gimbalPitchArray) {
      this.mqttClient.publish(
        `/to/${this.module.droneSerialNumber}/msg_waypoints/MISSION`,
        `{"type": "camera_mission", "gimbal_pitch_array" : [${gimbalPitchArray}], "should_capture" : ${shouldCapture}}`
      );
    } else {
      this.mqttClient.publish(
        `/to/${this.module.droneSerialNumber}/msg_waypoints/MISSION`,
        `{"type": "camera_mission", "gimbal_pitch_array" : [], "should_capture" : ${shouldCapture}}`
      );
    }
  }
  stop() {
    this.mqttClient.publish(
      `/to/${this.module.droneSerialNumber}/MISSION_STOP`,
      `{"stop" : true}`
    );
    console.log('Stop');
  }
  // expStop(){
  //   this.mqttClient.publish(`/to/${this.module.droneSerialNumber}/msg_waypoints/EXP_STOP`, `{"stop" : true}`)
  // }
  // expStart(){
  //   this.mqttClient.publish(`/to/${this.module.droneSerialNumber}/msg_waypoints/EXP_START`, `{"start" : true}`)
  // }
  /**
   * UPLOAD
   */
  ftpUploadInfo(data) {
    /* stringify된 data를 다시 객체로 가공해서 새로운 객체에 담아준다. */
    let dataObj = JSON.parse(data);
    let newFtpUploadInfo = {
      ip: dataObj.ftpIp,
      port: dataObj.ftpPort,
      id: dataObj.ftpId,
      pw: dataObj.ftpPassword,
      url: dataObj.url,
    };

    /* 다시 객체를 stringify해서 보낸다. */
    this.mqttClient.publish(
      `/to/${this.module.droneSerialNumber}/FTP_INFO`,
      `${JSON.stringify(newFtpUploadInfo)}`
    );

    console.log('UPLOAD');
  }
  /**
   * DOWNLOAD
   */
  ftpDownloadInfo(data) {
    /* stringify된 data를 다시 객체로 가공해서 새로운 객체에 담아준다. */
    let dataObj = JSON.parse(data);
    let newFtpUploadInfo = {
      ip: dataObj.ftpIp,
      port: dataObj.ftpPort,
      id: dataObj.ftpId,
      pw: dataObj.ftpPassword,
      url: dataObj.url,
    };

    /* 다시 객체를 stringify해서 보낸다. */
    this.mqttClient.publish(
      `/to/${this.module.droneSerialNumber}/FTP_INFO`,
      `${JSON.stringify(newFtpUploadInfo)}`
    );
  }
  udpInfo(ip, udp_port){
    this.mqttClient.publish(
      `/to/${this.module.droneSerialNumber}/UDP_INFO`,
      `{"ip" : "${ip}", "port" : "${udp_port}"}`
    );
  }

  sendMapInfo(facilityId, facilityName, mapId) {
    this.mqttClient.publish(
      `/to/${this.module.droneSerialNumber}/MAP_INFO`,
      `{"facility_id" : ${facilityId}, "facility_name" : "${facilityName}", "map": ${mapId}}`
    );
  }
  localizationConfig(minX, maxX, minY, maxY, minZ, maxZ) {
    this.mqttClient.publish(
      `/to/${this.module.droneSerialNumber}/msg_sirius_localization_config/LOCALIZATION_CONFIG`,
      `{ "minX": ${minX}, "maxX" : ${maxX}, "minY": ${minY}, "maxY" : ${maxY}, "minZ": ${minZ}, "maxZ" : ${maxZ}}`
    );
  }
  /**
   * start : 구 데이터 관리
   */
  dataManagementDirectory() {
    this.mqttClient.publish(
      `/to/${this.module.droneSerialNumber}/msg_data_management/REQUIRE_DIRECTORY`,
      `{'require' : true}`
    );
  }
  dataManagementErase(data) {
    this.mqttClient.publish(
      `/to/${this.module.droneSerialNumber}/msg_data_management/REQUIRE_ERASE`,
      `{ "info": ${data}}`
    );
  }
  dataManagementCopy(data) {
    this.mqttClient.publish(
      `/to/${this.module.droneSerialNumber}/msg_data_management/REQUIRE_COPY`,
      data
    );
  }
  dataManagementUSB() {
    this.mqttClient.publish(
      `/to/${this.module.droneSerialNumber}/msg_data_management/REQUIRE_USB`,
      `{'require' : true}`
    );
  }
  /**
   * end : 구 데이터 관리
   */
  /**
   * 데이터 관리 메세지 날리기
   * @param {object} data JSON.stringify 된 데이터
   * @example data : '{"action" : "require_directory", "results" : ["a","b"]}'
   */
  requireDirectoryDataTransfer() {
    // 디렉토리 요청
    this.mqttClient.publish(
      `/to/${this.module.droneSerialNumber}/DATA_TRANSFER`,
      `{"action" : "require_directory", "item" : null}`
    );
  }
  requireRemovalDataTransfer(removalData) {
    // 디렉토리 삭제 요청
    this.mqttClient.publish(
      `/to/${this.module.droneSerialNumber}/DATA_TRANSFER`,
      `{"action" : "require_removal", "item" : ${removalData}}`
    );
  }
  requireCopyDataTransfer(copyData) {
    // 디렉토리 복사 요청
    this.mqttClient.publish(
      `/to/${this.module.droneSerialNumber}/DATA_TRANSFER`,
      `{"action" : "require_copy", "item" : ${copyData}}`
    );
  }
  requireUSBDataTransfer() {
    // USB 리스트 요청
    this.mqttClient.publish(
      `/to/${this.module.droneSerialNumber}/DATA_TRANSFER`,
      `{"action" : "require_usb", "item" : null}`
    );
  }
  sendHandyShootingTypeStatus(mode, status) {
    this.mqttClient.publish(
      `/to/${this.module.droneSerialNumber}/handy/SHOOTING_STATUS`,
      `{"type" : "${mode}", "shooting_status" : "${status}"}`
    );
  }
  sendHandyGimbalDegree(yaw, pitch) {
    //  yaw: x, pitch: y
    this.mqttClient.publish(
      `/to/${this.module.droneSerialNumber}/handy/GIMBAL_ANGLE`,
      `{"pitch" : ${pitch}, "yaw" : ${yaw}}`
    );
  }
  sendHandyDayNightTime(time) {
    // 주간 400 야간 800 보내짐
    this.mqttClient.publish(
      `/to/${this.module.droneSerialNumber}/handy/ISO`,
      `{"iso" : ${time}}`
    );
  }
}

export default DroneControl;
