export default class USB {
  constructor(data) {
    this.usb_directory = null;
    this.usb_name = null;
    if (data) {
      this.usb_directory = data.usb_directory;
      this.usb_name = data.usb_name;
    }
  }
}
