export default class DroneType {
  constructor(droneType) {
    this.id = null;
    this.name = null;
    this.dimensionX = null;
    this.dimensionY = null;
    this.dimensionZ = null;
    this.criteria = null;
    // this.maxVoltage = null;
    // this.minVoltage = null;
    if (droneType) {
      this.id = droneType.id;
      this.name = droneType.name;
      this.dimensionX = droneType.dimensionX;
      this.dimensionY = droneType.dimensionY;
      this.dimensionZ = droneType.dimensionZ;
      this.criteria = droneType.criteria;
      // this.maxVoltage = droneType.maxVoltage;
      // this.minVoltage = droneType.minVoltage;
    }
  }
}
