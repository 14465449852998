<template>
  <header
    :class="{
      with_nav:
        $route.name !== 'login' &&
        $route.name !== 'home' &&
        $route.name !== 'user' &&
        $route.name !== 'notFound',
    }"
  >
    <div class="header_wrap">
      <div class="left_area">
        <h1 @click="goHome">
          <img src="@/../public/images/header-logo.png" alt="메인 홈으로" />
        </h1>
        <nav
          v-if="
            $route.name !== 'login' &&
            $route.name !== 'home' &&
            $route.name !== 'user' &&
            $route.name !== 'notFound'
          "
        >
          <div class="navigator_mapping nav_button">
            <button
              type="button"
              class="navigator_title"
              @click="mappingRouter"
              :class="{ 'on ': $route.name === 'mapping' }"
            >
              {{ $t('term.mapping') }}
            </button>
          </div>
          <div class="navigator_inspection nav_button">
            <button
              type="button"
              class="navigator_title"
              @click="inspectionRouter"
              :class="{ 'on ': $route.name === 'inspection' }"
            >
              {{ $t('term.inspection') }}
            </button>
          </div>
          <div class="navigator_report nav_button">
            <button
              type="button"
              class="navigator_title"
              @click="reportRouter"
              :class="{ 'on ': $route.name === 'report' }"
            >
              {{ $t('term.report') }}
            </button>
          </div>
        </nav>
      </div>
      <!-- 로그아웃/사용자 관리/내 정보 수정 컴포넌트 + 언어설정 -->
      <HeaderUserBox />
    </div>
  </header>
  <main>
    <slot></slot>
  </main>
</template>
<script>
import { mapState } from 'vuex';
import HeaderUserBox from './component/HeaderUserBox.vue';
import { addOutsideClickHandler } from '@/shared/util/handleOutsideClickHandler';
import { formatISO } from 'date-fns';
import { hasTrueValue } from '@/shared/util/hasTrueValue';

export default {
  name: 'DefaultLayout',
  components: {
    HeaderUserBox,
  },
  computed: {
    ...mapState([
      'user',
      'selectedFacility',
      'selectedFacilityMap',
      'missionRunningArray',
      'api',
    ]),
  },
  methods: {
    hasTrueValue,
    goHome() {
      // 미션 진행중인 드론이 array 안에 하나라도 존재한다면 다른 곳으로 이동할 수 없도록 막는다.
      // 전체 드론을 확인해야하는 경우에만 missionRunningArray를 사용한다.
      if (this.hasTrueValue(this.missionRunningArray)) {
        return this.$store.commit(
          'openAlert',
          `${this.$t('droneAlert.CantLeavePageOnMissionPlzEndMissionFirst')}`
        );
      }

      return this.$router.push('/');
    },
    reportRouter() {
      if (this.hasTrueValue(this.missionRunningArray)) {
        return this.$store.commit(
          'openAlert',
          `${this.$t('droneAlert.CantLeavePageOnMissionPlzEndMissionFirst')}`
        );
      }

      if (this.selectedFacility) {
        this.$router.push({
          name: 'report',
          params: { facilityId: this.selectedFacility.id },
        });

        return;
      }

      const isConfirmed = confirm(
        `${this.$t('otherAlert.pleaseSelectFacilityAgain')} ${this.$t(
          'otherAlert.goBackToMainPage'
        )}`
      );

      if (isConfirmed) {
        this.$router.push('/');
      }
    },
    mappingRouter() {
      if (this.hasTrueValue(this.missionRunningArray)) {
        return this.$store.commit(
          'openAlert',
          `${this.$t('droneAlert.CantLeavePageOnMissionPlzEndMissionFirst')}`
        );
      }

      if (this.selectedFacility) {
        if (this.selectedFacility.mapList) {
          let emptyMap = this.selectedFacility.mapList.find((map) => map.url == '');
          if (emptyMap) {
            this.$router.push({
              name: 'mapping',
              params: {
                facilityId: this.selectedFacility.id,
                mapId: emptyMap.id,
              },
            });
          } else {
            this.api.postMap(
              this.mappingRouterCallback,
              this.selectedFacility.id,
              formatISO(new Date())
            );
          }
        } else {
          this.api.postMap(
            this.mappingRouterCallback,
            this.selectedFacility.id,
            formatISO(new Date())
          );
        }
      } else {
        alert(
          `${this.$t('otherAlert.NoFacilitySelected')}\n${this.$t(
            'otherAlert.selectFacilityFromTheList'
          )}`
        );
      }
    },
    mappingRouterCallback(data) {
      let result = data.data.result;
      this.$router.push({
        name: 'mapping',
        params: { facilityId: this.selectedFacility.id, mapId: result.id },
      });
    },
    inspectionRouter() {
      if (this.hasTrueValue(this.missionRunningArray)) {
        return this.$store.commit(
          'openAlert',
          `${this.$t('droneAlert.CantLeavePageOnMissionPlzEndMissionFirst')}`
        );
      }

      if (this.selectedFacilityMap) {
        this.$router.push({
          name: 'inspection',
          params: {
            facilityId: this.selectedFacility.id,
            mapId: this.selectedFacilityMap.id,
          },
        });

        return;
      }

      const isConfirmed = confirm(
        `${this.$t('otherAlert.pleaseSelectMapAgain')} ${this.$t(
          'otherAlert.goBackToMainPage'
        )}`
      );

      if (isConfirmed) {
        this.$router.push('/');
      }
    },
    homeRouter() {
      this.$router.push({ name: 'home' });
    },
  },
  mounted() {
    // ref 이외의 영역을 클릭했을 때 닫히도록
    this.clickHandlerRemover = addOutsideClickHandler(this.$refs.headerMenuButton, () => {
      this.isMenuShow = false;
    });
  },
  beforeUnmount() {
    // ref 이외의 영역을 클릭했을 때 닫히도록
    if (this.clickHandlerRemover) {
      this.clickHandlerRemover();
    }
  },
};
</script>
<style lang="scss" scoped>
@import '@/style/component/header';

main {
  min-width: 100vw;
  min-height: 100vh;
}
</style>
