import axios from "axios";
import API from "@/shared/constant/api";
import { getCurrentTime } from "../util/getCurrentTime";
/**
 * 기본 정보를 instance화 해서 재사용한다.
 */
const baseUrl = `${API.HTTP_ORIGIN}/api`;

const axiosInstanceDefault = axios.create({
  baseURL: baseUrl,
});

const axiosInstance = axios.create({
  baseURL: baseUrl,
  timeout: 10000,
  timeoutErrorMessage:
    "요청 시간이 60초 이상 초과되었습니다. 다시 시도해주세요.",
});

export const postLogin = async (hashUserId, hashUserPassword) => {
  try {
    const response = await axiosInstance.post(`/users/verification`, {
      userLoginId: hashUserId,
      userPw: hashUserPassword,
    });

    return response.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const putLogin = async (userId, userInfo) => {
  try {
    const response = await axiosInstance.put(`/users/${userId}`, userInfo);

    return response;
  } catch (error) {
    console.log(error);
  }
};

export const getUser = async (userId) => {
  try {
    const response = await axiosInstance.get(`/users/${userId}`);
    return response.data;
  } catch (e) {
    console.log(e);
    return e.response;
  }
};

export const getUserList = async (affiliationId) => {
  try {
    const response = await axiosInstance.get(
      `/users?affiliationId=${affiliationId}`
    );
    return response.data;
  } catch (e) {
    console.log(e);
    return e.response;
  }
};

export const postUser = async (newUserInfo) => {
  try {
    const response = await axiosInstance.post(`/users`, newUserInfo);

    return response.data;
  } catch (e) {
    console.log(e);
    return e.response;
  }
};

export const patchUser = async (userId, newUserInfo) => {
  try {
    const response = await axiosInstance.patch(`/users/${userId}`, newUserInfo);

    return response.data;
  } catch (e) {
    console.log(e);
    return e.response;
  }
};

export const postUserThumbnail = async (userId, formData) => {
  try {
    const response = await axiosInstance.post(
      `/users/${userId}/thumbnails`,
      formData
    );

    return response.data;
  } catch (e) {
    console.log(e);
    return e.response;
  }
};

export const deleteUser = async (adminId, userId) => {
  const request = { adminId, userId };

  try {
    const response = await axiosInstance.delete(`/users`, { data: request });
    return response.data;
  } catch (e) {
    console.log(e);
    return e.response;
  }
};

export const getFacilitiesList = async (userId) => {
  try {
    const response = await axiosInstance.get(`/facilities?userId=${userId}`);
    return response.data;
  } catch (e) {
    console.log(e);
    return e.response;
  }
};

export const getFacility = async (facilityId) => {
  try {
    const response = await axiosInstance.get(`/facilities/${facilityId}`);
    return response;
  } catch (e) {
    console.log(e);
    return e.response;
  }
};

export const postFacility = async ({
  userId,
  name,
  description,
  location,
  typeName,
  createdDatetime,
}) => {
  try {
    const response = await axiosInstance.post(`/facilities?userId=${userId}`, {
      userId,
      name,
      description,
      location,
      typeName,
      createdDatetime,
    });

    return response.data;
  } catch (e) {
    console.log(e);
    return e.response;
  }
};

export const deleteFacility = async (facilityId) => {
  try {
    const response = await axiosInstance.delete(`/facilities/${facilityId}`);
    return response.data;
  } catch (e) {
    console.log(e);
    return e;
  }
};

export const putFacility = async (facilityId, editInfo) => {
  try {
    const response = await axiosInstance.put(
      `/facilities/${facilityId}`,
      editInfo
    );

    return response;
  } catch (e) {
    console.log(e);
    return e.response;
  }
};

export const postCreateThumbnail = async (facilityId, file) => {
  try {
    let formData = new FormData();
    formData.append("file", file);

    const response = await axiosInstance.post(
      `/facilities/${facilityId}/thumbnails`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return response;
  } catch (e) {
    console.log(e);
    return e.response;
  }
};

export const getMaps = async (facilityId) => {
  try {
    const response = await axiosInstance.get(`/facilities/${facilityId}/maps`);
    return response;
  } catch (e) {
    console.log(e);
    return e.response;
  }
};

export const getMapsByUserId = async (userId) => {
  try {
    const response = await axiosInstance.get(
      `/facilities/maps?userId=${userId}`
    );
    return response;
  } catch (e) {
    console.log(e);
    return e.response;
  }
};

export const getMap = async (facilityId, mapId) => {
  try {
    const response = await axiosInstance.get(
      `/facilities/${facilityId}/maps/${mapId}`
    );
    return response;
  } catch (e) {
    console.log(e);
    return e.response;
  }
};

export const postMap = async (facilityId) => {
  try {
    const response = await axiosInstance.post(
      `/facilities/${facilityId}/maps`,
      {
        facilityId: facilityId,
        name: "GlobalMap.pcd",
        url: "",
        createdDatetime: getCurrentTime(),
      }
    );

    return response;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const getAlbums = async (withStatus = false, facilityId, mapId) => {
  try {
    let query = "/albums";
    const queryParams = [];

    if (facilityId) {
      queryParams.push(`facilityId=${facilityId}`);

      // mapId는 facilityId가 있을 때만 사용할 수 있음
      if (mapId) {
        queryParams.push(`facilityMapId=${mapId}`);
      }
    }

    // withStatus가 true일 때만 include 파라미터 추가
    if (withStatus) {
      queryParams.push("include=crack_recognitions");
    }

    // queryParams가 있을 경우 쿼리스트링을 생성
    if (queryParams.length > 0) {
      query += `?${queryParams.join("&")}`;
    }

    const response = await axiosInstance.get(query);
    return response;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const getAnalysesList = async (albumId) => {
  try {
    const response = await axiosInstance.get(
      `/albums/${albumId}/crack-recognitions`
    );

    return response;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const getDrones = async (affiliationId) => {
  try {
    const response = await axiosInstance.get(
      `/drones?affiliationId=${affiliationId}`
    );

    return response;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const getDroneSystemLogList = async (userId) => {
  try {
    const response = await axiosInstance.get(
      `/drone-systems/logs?userId=${userId}`
    );

    return response;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const post3DModel = async (facilityId, body) => {
  try {
    const response = await axiosInstanceDefault.post(
      `/facility-model-3d/${facilityId}/rois/roi-parts`,
      body
    );

    return response;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const get3DModels = async (facilityId) => {
  try {
    const response = await axiosInstanceDefault.post(
      `/facility-model-3d/${facilityId}/rois/roi-parts`
    );

    return response;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const delete3DModel = async (facilityModel3DId, roiPartId) => {
  try {
    const response = await axiosInstanceDefault.delete(
      `/facility-model-3d/${facilityModel3DId}/rois/roi-parts/${roiPartId}`
    );

    return response;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const importUpload3DModelFile = async (
  facilityId,
  facilityModel3DId,
  formData
  // progressGage
) => {
  try {
    const response = await axiosInstanceDefault.post(
      `/facility-model-3d/${facilityId}/rois/roi-parts/${facilityModel3DId}/data-imports`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        // onUploadProgress: (progressEvent) => {
        //   var percentComplete = Math.round(
        //     (progressEvent.loaded * 100) / progressEvent.total
        //   );
        //   if (progressGage) {
        //     progressGage.style.width = percentComplete / 2 + "%";
        //   }
        // },
      }
    );

    return response;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const getElevationSetList = async (facilityModel3DId, roiPartId) => {
  try {
    const response = await axiosInstanceDefault.get(
      `/facility-model-3d/${facilityModel3DId}/rois/roi-parts/${roiPartId}/elevation-sets?all=true`
    );

    return response;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const deleteSummaryTab = async (
  facilityModel3DId,
  roiPartId,
  elevationSetId,
  elevationId
) => {
  try {
    const response = await axiosInstance.delete(
      `/facility-model-3d/${facilityModel3DId}/rois/roi-parts/${roiPartId}/elevation-sets/${elevationSetId}/elevations/${elevationId}`
      // `/facility-model-3d/${facilityModel3DId}/rois/roi-parts/${roiPartId}/elevation-set/${elevationSetId}/inspection-network-maps/${networkMapId}`
    );

    return response;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const getElevationCrackRecognition = async (
  facilityModel3DId,
  roiPartId,
  elevationSet,
  crackRecognition
) => {
  try {
    const response = await axiosInstanceDefault.get(
      `/facility-model-3d/${facilityModel3DId}/rois/roi-parts/${roiPartId}/elevation-sets/${elevationSet}/crack-recognitions/${crackRecognition}`
    );

    return response;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const getElevationCrackRecognitionList = async (
  facilityModel3DId,
  roiPartId,
  elevationSetId
) => {
  try {
    // 나중에 리펙토링 될 가능성이 있음 -> 현재는 배열에서 첫번째 아이템에 대한 아이디를 넣어주는 중,,
    const response = await axiosInstanceDefault.get(
      `/facility-model-3d/${facilityModel3DId}/rois/roi-parts/${roiPartId}/elevation-sets/${elevationSetId}/crack-recognitions?all=true`
    );

    return response.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};
