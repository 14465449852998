class SSE_Upload {
  constructor(url, progressGage, callback) {
    this.url = url;
    this.eventSource = new EventSource(this.url);
    this.progressGage = progressGage;
    this.callback = callback;
    this.onMessage = this.onMessage.bind(this);
    this.onError = this.onError.bind(this);
    this.init();
  }
  init() {
    this.eventSource.onmessage = this.onMessage;
    this.eventSource.onerror = this.onError;
  }
  onMessage(event) {
    let albumId = this.url.match(/albums\/(\d+)/)[1];
    if (event.data != "ping") {
      if (event.data.includes("Error")) {
        this.callback(false, albumId, event.data);
        return;
      }
      this.progressGage.style.width = event.data / 2 + 50 + "%";
      if (event.data == 100) {
        this.progressGage.style.width = "0%";
        this.callback(true);
        this.cleanup();
      } else {
        this.callback(true, albumId, event.data);
      }
    }
  }
  onError() {
    let albumId = this.url.match(/albums\/(\d+)/)[1];
    this.progressGage.style.width = "0%";
    this.callback(false, albumId);
    this.cleanup();
  }
  cleanup() {
    if (this.eventSource) {
      this.eventSource.close(); // 연결 종료
      this.eventSource.onmessage = null; // 이벤트 리스너 제거
      this.eventSource.onerror = null;
      this.eventSource = null; // 객체 참조 해제
    }
  }
}

export default SSE_Upload;
