<template>
  <div class="report_detail_info_wrap">
    <div class="report_detail_info" v-if="selectedPhoto?.camera">
      <div class="report_detail_info_title">
        {{ $t("term.shootingConditions") }}
      </div>
      <div class="report_detail_info_content">
        <div class="report_detail_info_content_title">
          {{ $t("term.fStop") }}
        </div>
        <div class="report_detail_info_content_value">
          {{ selectedPhoto.camera.fstop }}
        </div>
      </div>
      <div class="report_detail_info_content">
        <div class="report_detail_info_content_title">
          {{ $t("term.exposureTime") }}
        </div>
        <div class="report_detail_info_content_value">
          {{ selectedPhoto.camera.exposureTime }}
        </div>
      </div>
      <div class="report_detail_info_content">
        <div class="report_detail_info_content_title">ISO</div>
        <div class="report_detail_info_content_value">
          {{ selectedPhoto.camera.iso }}
        </div>
      </div>
      <div class="report_detail_info_content">
        <div class="report_detail_info_content_title">
          {{ $t("term.focalLength") }}
        </div>
        <div class="report_detail_info_content_value">
          {{ selectedPhoto.camera.focusDistance }}
        </div>
      </div>
      <div class="report_detail_info_content">
        <div class="report_detail_info_content_title">
          {{ $t("term.exposureBias") }}
        </div>
        <div class="report_detail_info_content_value">
          {{ selectedPhoto.camera.exposureBias }}
        </div>
      </div>
    </div>
    <div class="report_detail_info" v-if="selectedPhoto?.albumPhotoPos">
      <div class="report_detail_info_title">
        {{ $t("term.positionAndPosture") }}
      </div>
      <div class="report_detail_info_content" id="report_detail_x">
        <div class="report_detail_info_content_title">X</div>
        <div class="report_detail_info_content_value">
          {{ selectedPhoto.albumPhotoPos.x.toFixed(2) }} m
        </div>
      </div>
      <div class="report_detail_info_content" id="report_detail_y">
        <div class="report_detail_info_content_title">Y</div>
        <div class="report_detail_info_content_value">
          {{ selectedPhoto.albumPhotoPos.y.toFixed(2) }} m
        </div>
      </div>
      <div class="report_detail_info_content" id="report_detail_z">
        <div class="report_detail_info_content_title">Z</div>
        <div class="report_detail_info_content_value">
          {{ selectedPhoto.albumPhotoPos.z.toFixed(2) }} m
        </div>
      </div>
      <div class="report_detail_info_content" id="report_detail_roll">
        <div class="report_detail_info_content_title">ROLL</div>
        <div class="report_detail_info_content_value">
          {{ selectedPhoto.albumPhotoPos.roll.toFixed(2) }} rad
        </div>
      </div>
      <div class="report_detail_info_content" id="report_detail_pitch">
        <div class="report_detail_info_content_title">PITCH</div>
        <div class="report_detail_info_content_value">
          {{ selectedPhoto.albumPhotoPos.pitch.toFixed(2) }} rad
        </div>
      </div>
      <div class="report_detail_info_content" id="report_detail_yaw">
        <div class="report_detail_info_content_title">YAW</div>
        <div class="report_detail_info_content_value">
          {{ selectedPhoto.albumPhotoPos.yaw.toFixed(2) }} rad
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    selectedPhoto: Object,
  },
};
</script>

<style lang="scss">
.report_detail_info_wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 200px;
  padding: 10px;
  border-bottom: 1px solid rgba(1, 250, 254, 0.2);
}
.report_detail_info {
  display: flex;
  align-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  height: 85px;
}
.report_detail_info_title {
  display: flex;
  width: 100%;
  height: 30px;
  align-items: center;
  font-size: 1.2rem;
  font-weight: bold;
  color: rgb(1, 254, 250);
}
.report_detail_info_content {
  display: flex;
  justify-content: space-between;
  width: calc(100% / 3 - 20px);
  margin-right: 20px;
  height: 25px;
}
.report_detail_info_content_title {
  display: flex;
  align-items: center;
  width: 50%;
  height: 100%;
  font-size: 1rem;
  color: $main_white;
}
#report_detail_x .report_detail_info_content_title {
  width: 30%;
}
#report_detail_y .report_detail_info_content_title {
  width: 30%;
}
#report_detail_z .report_detail_info_content_title {
  width: 30%;
}
#report_detail_roll .report_detail_info_content_title {
  width: 15%;
}
#report_detail_pitch .report_detail_info_content_title {
  width: 15%;
}
#report_detail_yaw .report_detail_info_content_title {
  width: 15%;
}
.report_detail_info_content_value {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: calc(50% - 10px);
  height: 25px;
  border: 1px solid rgba(1, 250, 254, 0.2);
  border-radius: 5px;
  font-size: 1rem;
  color: $main_white;
  padding: 0px 5px 0px 5px;
}
#report_detail_x .report_detail_info_content_value {
  width: calc(50% - 10px);
}
#report_detail_y .report_detail_info_content_value {
  width: calc(50% - 10px);
}
#report_detail_z .report_detail_info_content_value {
  width: calc(50% - 10px);
}
#report_detail_roll .report_detail_info_content_value {
  width: calc(50% - 10px);
}
#report_detail_pitch .report_detail_info_content_value {
  width: calc(50% - 10px);
}
#report_detail_yaw .report_detail_info_content_value {
  width: calc(50% - 10px);
}
</style>
