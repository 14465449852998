<template>
  <div class="data_management_header">
    <div class="data_management_header_title">{{ $t('term.moduleId') }}</div>
    <div class="data_management_select" @click="clickDroneSelect">
      {{ selectedDrone.droneSerialNumber }}
    </div>
    <div
      class="data_management_select_unlink button"
      v-if="selectedDrone.droneSerialNumber"
      @click="checkDroneStatus(null)"
    ></div>
    <div
      v-if="droneSelectModal"
      class="data_management_select_option_wrap"
      :style="{ height: getHeightDrone }"
    >
      <div
        v-for="(drone, index) in droneList"
        :key="index"
        class="data_management_select_option"
        @click="checkDroneStatus(drone)"
      >
        {{ drone.droneSerialNumber }}
      </div>
    </div>
  </div>
  <div class="data_management_content_wrap">
    <div class="data_management_content" v-for="(data, index) in dataList" :key="index">
      <div
        class="data_management_content_title"
        :class="{ on: data.facility.open }"
        @click="changeFacilityOpen(data)"
      >
        {{ data.facility.name }}
      </div>
      <div class="data_management_content_main" v-if="data.facility.open">
        <div
          class="data_management_content_map"
          v-for="(map, index) in data.mapList"
          :key="index"
        >
          <div class="data_management_content_map_title_wrap">
            <div
              class="data_management_content_map_title"
              :class="{ on: map.open }"
              @click="changeMapOpen(map)"
            >
              [{{ $t('term.map') }}] {{ map.name }}
            </div>
            <!-- <div class="data_management_content_map_title_checkbox" :class="{'on' : map.checked}"
                 @click="changeMapChecked(map)">
            </div> -->
          </div>
          <div class="data_management_content_map_main" v-if="map.open">
            <div class="data_management_content_image_wrap" v-if="map.existence">
              <div class="data_management_content_image">
                [{{ $t('term.pointCloudMap') }}]
              </div>
              <div
                class="data_management_content_image_checkbox"
                :class="{ on: map.checked }"
                @click="changeMapChecked(map)"
              ></div>
              <div class="data_management_content_another_image_wrap">
                <div
                  class="data_management_content_image_spinner"
                  v-if="map.state == 'loading'"
                ></div>
                <div
                  class="data_management_content_image_complete"
                  v-else-if="map.state == 'complete'"
                ></div>
                <div
                  class="data_management_content_image_text"
                  v-if="map.state == 'loading'"
                >
                  {{ map.percentage }}
                </div>
              </div>
            </div>
            <div
              class="data_management_content_image_wrap"
              v-for="(image, index) in map.pictures"
              :key="index"
            >
              <div class="data_management_content_image">
                [{{ $t('term.album') }}] {{ image.name }}
              </div>
              <div
                class="data_management_content_image_checkbox"
                :class="{ on: image.checked }"
                @click="changeImageChecked(image)"
              ></div>
              <div class="data_management_content_another_image_wrap">
                <div
                  class="data_management_content_image_spinner"
                  v-if="image.state == 'loading'"
                ></div>
                <div
                  class="data_management_content_image_complete"
                  v-if="image.state == 'complete'"
                ></div>
                <div
                  class="data_management_content_image_text"
                  v-if="image.state == 'loading'"
                >
                  {{ image.percentage }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="data_management_footer">
    <div class="data_management_footer_message">{{ message.content }}</div>
    <div class="data_management_footer_button_wrap">
      <div
        class="data_management_footer_button button"
        v-if="state == 'ready'"
        @click="openModal"
      >
        {{ $t('button.copy') }}
      </div>
      <div
        class="data_management_footer_button button"
        v-if="state == 'ready'"
        @click="clickDelete"
      >
        {{ $t('button.delete') }}
      </div>
    </div>
  </div>
  <div class="data_management_modal_wrap" v-if="modalState" @click="closeModal">
    <div class="data_management_modal" @click.stop>
      <div class="data_management_modal_title_wrap">
        <div class="data_management_modal_title">
          {{ $t('term.storageDeviceSetting') }}
        </div>
        <div class="data_management_modal_close" @click="closeModal"></div>
      </div>
      <div class="data_management_modal_content">
        <div class="data_management_modal_select" @click="clickUSBSelect">
          {{ selectedUSB.usb_name }}
        </div>
        <div class="data_management_modal_button button" @click="checkUSB">
          {{ $t('button.confirm') }}
        </div>
        <div
          v-if="usbSelectModal"
          class="data_management_modal_select_option_wrap"
          :style="{ height: getHeightUSB }"
        >
          <div
            v-for="(usb, index) in usbList"
            :key="index"
            class="data_management_modal_select_option"
            @click="selectUsb(usb)"
          >
            {{ usb.usb_name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import Drone from '@/model/Drone.js';
import DroneControl from '@/module/Drone/DroneControl.js';
import DroneMonitor from '@/module/Drone/DroneMonitor.js';
import Site from '@/model/Site.js';
import Map from '@/model/Map.js';
import DroneHardData from '@/model/DroneHardData.js';
import DroneHardDataMap from '@/model/DroneHardDataMap.js';
import DroneHardDataImage from '@/model/DroneHardDataImage.js';
import USB from '@/model/USB.js';
import API from '@/shared/constant/api';

export default {
  computed: {
    ...mapState(['user', 'api']),
    getHeightDrone() {
      if (this.droneList) {
        return this.droneList.length * 25 + 'px';
      } else {
        return '0px';
      }
    },
    getHeightUSB() {
      if (this.usbList) {
        return this.usbList.length * 25 + 'px';
      } else {
        return '0px';
      }
    },
  },
  data() {
    return {
      droneList: [],
      droneSelectModal: false,
      selectedDrone: new Drone(),
      facilityList: [],
      dataList: [],
      state: 'initial',
      message: {
        interval: null,
        content: null,
      },
      currentData: null,
      modalState: false,
      usbList: [],
      selectedUSB: new USB(),
      usbSelectModal: false,
      droneSocket: null,
      droneConnectionInterval: null,
      removalItemArray: null,
      currentRemovalIndex: 0,
      copyItemArray: null,
      currentCopyIndex: 0,
    };
  },
  methods: {
    getFacilities() {
      this.api.getSites(this.user.id, this.getFacilitiesCallback);
    },
    getFacilitiesCallback(data) {
      let result = data.data.result;
      this.facilityList = [];
      if (result) {
        for (let i = 0; i < result.length; i++) {
          let site = new Site(result[i]);
          this.facilityList.push(site);
        }
        this.api.getAllMaps(this.getAllMapsCallback, this.user.id);
      }
    },
    getAllMapsCallback(data) {
      let result = data.data.result;
      if (result) {
        for (let i = 0; i < result.length; i++) {
          if (result[i].url != '') {
            let map = new Map(result[i]);
            let facility = this.facilityList.find(
              (facility) => facility.id == map.facilityId
            );
            facility.mapList.push(map);
          }
        }
      }
    },
    getDrones() {
      this.api.getDrones(this.getDronesCallback, this.user);
    },
    getDronesCallback(data) {
      let result = data.data.result;
      this.droneList = [];
      for (let i = 0; i < result.length; i++) {
        this.droneList.push(new Drone(result[i]));
      }
    },
    clickDroneSelect() {
      this.droneSelectModal = !this.droneSelectModal;
    },
    clickUSBSelect() {
      this.usbSelectModal = !this.usbSelectModal;
    },
    checkDroneStatus(drone) {
      if (drone) {
        this.api.getDrone(this.checkDroneStatusCallback, drone.id);
      } else {
        this.selectedDrone = new Drone();
        this.connectDrone();
      }
      this.droneSelectModal = false;
    },
    checkDroneStatusCallback(data) {
      let result = data.data.result;
      if (result.droneStatus === 'disConnecting') {
        this.selectedDrone = new Drone(result);
      } else {
        this.$store.commit('openAlert', this.$t('droneAlert.alreadyInUseId'));
        this.selectedDrone = new Drone();
      }
      this.connectDrone();
    },
    /**
     * 드론 연결 할 때 웹소켓으로 1초 마다 메세지 보내기
     */
    droneConnectWebsocket(droneSerialNumber) {
      if (this.droneSocket) {
        this.checkDroneStatusCallback();
        return;
      }

      const socket = new WebSocket(
        `${API.WEBSOCKET_ORIGIN}/ws/drones/${droneSerialNumber}`
      );

      socket.onopen = () => {
        this.droneConnectionInterval = setInterval(() => {
          socket.send(JSON.stringify({ message: JSON.stringify(droneSerialNumber) }));
        }, 1000);
        console.log(`Analysis Web Socket Open`);
      };

      socket.onclose = () => {
        console.log(`Analysis Web Socket Close`);
      };

      this.droneSocket = socket; // 소켓 저장
    },

    /**
     * 드론 연결 끊기 (웹소켓 종료)
     */
    stopDroneConnection() {
      // 타이머 정지
      if (this.droneConnectionInterval) {
        clearInterval(this.droneConnectionInterval);
        this.droneConnectionInterval = null;
      }

      // 웹소켓 닫기
      if (this.droneSocket) {
        this.api.putDrone(this.droneMonitor.module.id, 'disConnecting');

        this.droneSocket.close();

        this.droneSocket = null; // 객체 초기화
      }
    },
    /**
     * 드론 시리얼넘버 선택
     */
    connectDrone() {
      if (this.droneMonitor) {
        this.api.putDrone(this.droneMonitor.module.id, 'disConnecting');
        this.stopDroneConnection(); // 드론 연결 감지 웹소켓 끊기
        this.droneMonitor.closeMqtt();
        this.droneMonitor = null;
        this.droneControl = null;
        this.state = 'initial';
        this.message.content = null;
        this.currentData = null;
        this.dataList = [];
      }
      if (this.selectedDrone.droneSerialNumber) {
        this.droneMonitor = new DroneMonitor(this.selectedDrone);
        this.api.putDrone(this.droneMonitor.module.id, 'connecting');
        this.droneConnectWebsocket(this.selectedDrone.droneSerialNumber); // 드론 연결
        this.droneMonitor.subDataTransfer(this.dataTransferCallback); // 드론 데이터 읽어오기
        this.droneMonitor.readMsg();

        this.droneControl = new DroneControl(
          this.droneMonitor.mqttClient,
          this.selectedDrone
        );
        this.state = 'read';
        this.droneControl.requireDirectoryDataTransfer(); // 드론 데이터 요청
        this.message.content = this.$t('droneAlert.readingData');
      }
    },
    selectUsb(usb) {
      if (usb) {
        this.selectedUSB = usb;
      } else {
        this.selectedUSB = new USB();
      }
      this.usbSelectModal = false;
    },
    /**
     * 데이터 받아오기/usb 받아오기/복사하기/삭제하기
     * 1. 존재하는 데이터 받기
     * 2. 삭제 해야하는 데이터 삭제해서 성공 유무 받기
     * 3. 다시 데이터 요청해서 뿌리기
     * @example data : {“action” : “require_directory”, "results" : []}
     */
    dataTransferCallback(data) {
      if (data.action === 'response_directory') {
        // 존재하는 디렉토리 리스트 받아오는 토픽
        // 리스트 받아온 다음 불필요한 파일들 삭제
        this.removeUselessData(data.results);
        return;
      }

      if (data.action === 'response_removal') {
        // 디렉토리에서 필요 없는 데이터 삭제 성공 유무 받아오는 토픽
        if (!this.removalItemArray || this.removalItemArray.length === 0) {
          return this.$store.commit('openAlert', this.$t('droneAlert.noItemToDelete'));
        }

        if (data?.results?.success) {
          // this.dataList[0].mapList[j].state = 'complete';
          /**
           * 무조건 내가 보낸 아이템에 대한 삭제 성공/실패라는 뜻으로 이해하고 진행한다.
           * 일단 removalItemArray의 0번째가 들어왔고, 거기에 대한 성공일 경우 부터 시작.
           */
          this.currentRemovalIndex = this.currentRemovalIndex + 1;

          if (this.currentRemovalIndex < this.removalItemArray.length) {
            // 삭제할 아이템이 남아있는 경우
            this.droneControl.requireRemovalDataTransfer(
              JSON.stringify(this.removalItemArray[this.currentRemovalIndex])
            );
          } else {
            // 모든 삭제 성공이라면 다시 데이터를 요청한다
            this.state = 'ready';
            this.message.content = null;
            this.droneControl.requireDirectoryDataTransfer(); // (신) 데이터 요청할 때 어떤 값을 파라미터로 보내는지??
          }
        }
      }

      if (data.action === 'response_usb') {
        // usb 리스트 받아오기
        this.usbList = [];
        if (data.results) {
          for (let i = 0; i < data.results.length; i++) {
            this.usbList.push(new USB(data.results[i]));
          }
          return;
        } else {
          this.$store.commit('openAlert', this.$t('droneAlert.noUSBConnected'));
          return;
        }
      }

      if (data.action === 'response_copy') {
        const targetMapId = this.copyItemArray[this.currentCopyIndex].map_id;
        const targetPictureName = this.copyItemArray[this.currentCopyIndex].picture;
        // 데이터 복사 결과 받아오기
        if (data.results.state === 'synchronization') {
          this.message.content = this.$t('droneAlert.SynchronizingData');
        } else if (data.results.state === 'onGoing') {
          // 복사 진행 중
          this.message.content = this.$t('droneAlert.copyingDataInProgress');

          if (this.copyItemArray[this.currentCopyIndex].type === 'pointCloud') {
            // pointCloud 복사인 경우
            this.dataList.forEach((item) => {
              item.mapList.forEach((map) => {
                if (map.id === targetMapId) {
                  // map id와 동일하고 체크되어있는 아이템을 찾아서 percentage바꿔준다.
                  map.percentage = data.results.percentage;
                }
              });
            });
          } else if (this.copyItemArray[this.currentCopyIndex].type === 'picture') {
            this.dataList.forEach((item) => {
              item.mapList.forEach((map) => {
                map.pictures.forEach((picture) => {
                  if (picture.name === targetPictureName) {
                    // picture.name 동일하고 체크되어있는 아이템을 찾아서 percentage바꿔준다.
                    picture.percentage = data.results.percentage;
                  }
                });
              });
            });
          }
        } else if (data.results.state === 'complete') {
          // 복사 완료
          if (this.copyItemArray[this.currentCopyIndex].type === 'pointCloud') {
            // pointCloud 복사인 경우
            this.dataList.forEach((item) => {
              item.mapList.forEach((map) => {
                if (map.id === targetMapId && map.checked) {
                  // map id와 동일하고 체크되어있는 아이템을 찾아서 false시켜주고 complete해준다.
                  map.checked = false;
                  map.percentage = null;
                  map.state = 'complete';
                }
              });
            });
          } else if (this.copyItemArray[this.currentCopyIndex].type === 'picture') {
            this.dataList.forEach((item) => {
              item.mapList.forEach((map) => {
                map.pictures.forEach((picture) => {
                  if (picture.name === targetPictureName && picture.checked) {
                    // picture.name과 동일하고 체크되어있는 아이템을 찾아서 false시켜주고 complete해준다.
                    picture.checked = false;
                    picture.percentage = null;
                    picture.state = 'complete';
                  }
                });
              });
            });
          }

          this.message.content = this.$t('droneAlert.copyingDataCompleted');

          this.currentCopyIndex = this.currentCopyIndex + 1;

          if (this.currentCopyIndex < this.copyItemArray.length) {
            // copy할 아이템이 남아있는 경우
            this.droneControl.requireCopyDataTransfer(
              JSON.stringify(this.copyItemArray[this.currentCopyIndex])
            );
          } else {
            // copy할 아이템이 없는 경우 그냥 전체 상태를 ready로 변경
            this.state = 'ready';
            this.message.content = null;
          }
        } else if (data.results.state === 'fail') {
          // 복사 완료
          if (this.copyItemArray[this.currentCopyIndex].type === 'pointCloud') {
            // pointCloud 복사인 경우
            this.dataList.forEach((item) => {
              item.mapList.forEach((map) => {
                if (map.id === targetMapId && map.checked) {
                  // map id와 동일하고 체크되어있는 아이템을 찾아서 complete해준다.
                  map.percentage = null;
                  map.state = null;
                }
              });
            });
          } else if (this.copyItemArray[this.currentCopyIndex].type === 'picture') {
            this.dataList.forEach((item) => {
              item.mapList.forEach((map) => {
                map.pictures.forEach((picture) => {
                  if (picture.name === targetPictureName && picture.checked) {
                    // picture.name과 동일하고 체크되어있는 아이템을 찾아서 complete해준다.
                    picture.percentage = null;
                    picture.state = null;
                  }
                });
              });
            });
          }
          // 복사 실패
          if (Number(data.results.percentage) === -1) {
            return this.$store.commit(
              'openAlert',
              this.$t('droneAlert.NotEnoughUSBCapacity')
            );
          } else if (Number(data.results.percentage) === -2) {
            return this.$store.commit(
              'openAlert',
              this.$t('droneAlert.fileNotExistInModule')
            );
          }
        }
      }
    },
    /**
     * 디렉토리 데이터 받아와서 삭제 처리하는 함수
        배열을 받아올 때에는 pictures / 이미지 삭제 요청을 보낼때에는 picture
     */
    removeUselessData(data) {
      // 1. 모듈에 있는 디렉토리가 DB에 단순히 존재하는지 판단해서 삭제시켜줄 때
      this.dataList = []; // 항상 초기화하고 시작
      this.removalItemArray = [];
      this.currentRemovalIndex = 0;
      this.state = 'sync';
      this.message.content = this.$t('droneAlert.SynchronizingData');

      if (!data) {
        this.state = 'ready';
        this.message.content = null;
        this.$store.commit('openAlert', this.$t('droneAlert.dataDoesNotExist'));
        return;
      }

      for (let i = 0; i < data.length; i++) {
        let hasFacility = this.facilityList.find(
          (facility) => facility.name === data[i].facility_name
        );

        if (hasFacility) {
          // 시설물이 DB에 존재한다면 mapId 확인
          let hasMap = hasFacility.mapList.find(
            (map) => map.id === Number(data[i].map_id)
          );

          if (hasMap) {
            // 맵이 존재한다면 시설물 정보 넣어주고
            let finedData = this.dataList.find(
              (data) => data.facility_name === hasFacility.name
            );
            if (!finedData) {
              finedData = new DroneHardData(hasFacility);
              this.dataList.push(finedData);
            }

            let newMap = new DroneHardDataMap(hasMap, data[i].map_existence);
            finedData.mapList.push(newMap);
            if (data[i].pictures) {
              // 맵에 이미지 리스트가 있다면 그것도 넣어준다.
              for (let j = 0; j < data[i].pictures.length; j++) {
                newMap.pictures.push(new DroneHardDataImage(data[i].pictures[j]));
              }
            }
          } else {
            // map이 존재하지 않는다면 삭제 배열에 담아
            this.removalItemArray.push({
              type: 'mapId',
              facility_name: data[i].facility_name,
              map_id: Number(data[i].map_id),
              picture: null,
            });
          }
        } else {
          // 시설물이 DB에 존재하지 않는다면 삭제 배열에 담아
          this.removalItemArray.push({
            type: 'facilityName',
            facility_name: data[i].facility_name,
            map_id: null,
            picture: null,
          });
        }
      }

      if (this.removalItemArray?.length > 0) {
        // 지워야 할 것들이 있다면 지우는 요청을 '한개씩' 보내기
        this.state = 'erase';
        this.message.content = this.$t('droneAlert.requestToDeleteData');

        // 지워졌다는 success 토픽을 받아보기 위해서 일단 배열의 첫번째 아이템을 보낸다.

        this.droneControl.requireRemovalDataTransfer(
          JSON.stringify(this.removalItemArray[0])
        );
        return;
      } else {
        this.state = 'ready';
        this.message.content = null;
        return;
      }
    },
    checkUSB() {
      if (this.selectedUSB.usb_name) {
        this.clickCopy();
        this.closeModal();
      } else {
        this.$store.commit('openAlert', this.$t('droneAlert.noStorageDeviceSelected'));
      }
    },
    changeFacilityOpen(data) {
      data.facility.open = !data.facility.open;
    },
    changeMapOpen(map) {
      map.open = !map.open;
    },
    changeMapChecked(map) {
      map.checked = !map.checked;
    },
    changeImageChecked(image) {
      image.checked = !image.checked;
    },
    /**
     * 복사
     * 이미지 배열은 pictures / 복사 요청을 보낼때에는 하나씩 요청하니까 picture
     */
    clickCopy() {
      this.state = 'copy';
      this.message.content = this.$t('droneAlert.startCopyingData');

      this.copyItemArray = [];
      this.currentCopyIndex = 0;

      for (let i = 0; i < this.dataList.length; i++) {
        for (let j = 0; j < this.dataList[i].mapList.length; j++) {
          if (this.dataList[i].mapList[j].checked) {
            // pointCloud 복사 체크
            let newData = {
              type: 'pointCloud',
              facility_name: this.dataList[i].facility.name,
              map_id: this.dataList[i].mapList[j].id,
              picture: null,
              usb: this.selectedUSB.usb_directory,
            };
            this.copyItemArray.push(newData);
            this.dataList[i].mapList[j].state = 'loading';
            this.currentData = this.dataList[i].mapList[j];
          }

          for (let k = 0; k < this.dataList[i].mapList[j].pictures.length; k++) {
            if (this.dataList[i].mapList[j].pictures[k].checked) {
              // 이미지 복사 체크
              let newData = {
                type: 'picture',
                facility_name: this.dataList[i].facility.name,
                map_id: this.dataList[i].mapList[j].id,
                picture: this.dataList[i].mapList[j].pictures[k].name,
                usb: this.selectedUSB.usb_directory,
              };
              this.copyItemArray.push(newData);
              this.dataList[i].mapList[j].pictures[k].state = 'loading';
              this.currentData = this.dataList[i].mapList[j].pictures[k];
            }
          }
        }
      }

      if (this.copyItemArray.length > 0) {
        this.droneControl.requireCopyDataTransfer(
          JSON.stringify(this.copyItemArray[this.currentCopyIndex])
        );

        return;
      } else {
        this.message.content = this.$t('droneAlert.copyingDataCompleted');
        this.state = 'ready';

        return this.$store.commit('openAlert', this.$t('droneAlert.noSelectedItem'));
      }
    },
    /**
     * 삭제
     * 이미지 배열은 pictures / 복사 요청을 보낼때에는 하나씩 요청하니까 picture
     */
    clickDelete() {
      this.state = 'erase';
      this.removalItemArray = [];
      this.currentRemovalIndex = 0;

      for (let i = 0; i < this.dataList.length; i++) {
        // 불러온 데이터 리스트를 반복문 돌린다.

        for (let j = 0; j < this.dataList[i].mapList.length; j++) {
          // 1. 맵 선택되었는지 확인해서 해당 맵의 포인트클라우드 삭제 배열에 추가
          if (this.dataList[i].mapList[j].checked) {
            this.removalItemArray.push({
              type: 'pointCloud',
              facility_name: this.dataList[i].facility.name,
              map_id: this.dataList[i].mapList[j].id,
              picture: null,
            });
          }

          // 2. 이미지 선택되었는지 확인해서 삭제 배열에 추가
          for (let k = 0; k < this.dataList[i].mapList[j].pictures.length; k++) {
            if (this.dataList[i].mapList[j].pictures[k].checked) {
              this.removalItemArray.push({
                type: 'picture',
                facility_name: this.dataList[i].facility.name,
                map_id: this.dataList[i].mapList[j].id,
                picture: this.dataList[i].mapList[j].pictures[k].name,
              });
            }
          }
        }
      }

      if (this.removalItemArray.length > 0) {
        // 삭제 성공/실패를 받아보기 위해 일단 첫번째 아이템만 보내고 후 처리는 dataTransferCallback함수에서 한다.
        this.droneControl.requireRemovalDataTransfer(
          JSON.stringify(this.removalItemArray[this.currentRemovalIndex])
        );
        return;
      } else {
        this.state = 'ready';
        this.message.content = null;
        return this.$store.commit('openAlert', this.$t('droneAlert.noSelectedItem'));
      }
    },
    closeModal() {
      this.modalState = false;
    },
    openModal() {
      this.droneControl.requireUSBDataTransfer(); // usb list 요청
      this.selectedUSB = new USB();
      this.modalState = true;
    },
  },
  mounted() {
    this.getFacilities();
    this.getDrones();
  },
  beforeUnmount() {
    this.stopDroneConnection(); // 페이지 이동시에도 웹소켓 끊기도록

    if (this.droneMonitor) {
      this.api.putDrone(this.droneMonitor.module.id, 'disConnecting');
      this.droneMonitor.closeMqtt();
      this.droneMonitor = null;
    }
    if (this.droneControl) this.droneControl = null;
  },
};
</script>
<style lang="scss" scoped>
.data_management_header {
  position: relative;
  @include flexbox(center, flex-start, row);
  width: 100%;
  height: 50px;
}
.data_management_header_title {
  @include flexbox(center, flex-start, row);
  width: 100px;
  height: 100%;
  font-size: 1.8rem;
  color: $main_white;
}
.data_management_select {
  @include flexbox(center, flex-start, row);
  padding-left: 10px;
  width: 80px;
  height: 25px;
  border-radius: 5px;
  font-size: 12px;
  color: $main_white;
  box-shadow: 0px 0px 4px 2px rgba(1, 250, 254, 0.3);
  background: rgba(23, 23, 23, 0.6) url('@/../public/images/chevronDown.svg') no-repeat
    right 10px center;
  background-size: 12px 12px;
  cursor: pointer;
}
.data_management_select_unlink {
  margin-left: 10px;
  width: 25px;
  height: 25px;
  background-image: url('@/../public/images/unlink.svg');
  background-size: 18px 18px;
}
.data_management_select_unlink:hover {
  background-image: url('@/../public/images/unlink_hover.svg');
  background-size: 18px 18px;
}
.data_management_select_option_wrap {
  position: absolute;
  @include flexbox(center, flex-start, column);
  left: 100px;
  top: 45px;
  width: 80px;
  height: 0px;
  padding: 0px 10px 0px 10px;
  border-radius: 5px;
  font-size: 12px;
  color: $main_white;
  box-shadow: 0px 0px 4px 2px rgba(1, 250, 254, 0.3);
  background: rgba(23, 23, 23);
  z-index: 22;
  cursor: pointer;
}
.data_management_select_option {
  @include flexbox(center, flex-start);
  width: 100%;
  height: 25px;
  border-bottom: 1px solid rgba(127, 253, 239, 0.5);
}
.data_management_select_option:last-of-type {
  border: none;
}
.data_management_select_option:hover {
  color: rgba(1, 250, 254, 0.5);
}
.data_management_content_wrap {
  @include flexbox(center, flex-start, column);
  width: 100%;
  max-height: 600px;
  overflow: auto;
}
.data_management_content_wrap::-webkit-scrollbar {
  width: 4px;
}
/* 스크롤바 트랙 스타일 */
.data_management_content_wrap::-webkit-scrollbar-track {
  background: transparent;
}
/* 스크롤바 손잡이 스타일 */
.data_management_content_wrap::-webkit-scrollbar-thumb {
  background: rgba(1, 250, 254, 0.8);
  border-radius: 5px;
}
/* 스크롤바 손잡이:hover 스타일 */
.data_management_content_wrap::-webkit-scrollbar-thumb:hover {
  background: rgba(1, 250, 254, 1);
}
.data_management_content {
  @include flexbox(center, flex-start, column);
  width: 100%;
  font-size: 1.5rem;
}
.data_management_content_title {
  @include flexbox(center, flex-start);
  padding-left: 20px;
  background: url('@/../public/images/caret_right_white.svg') no-repeat left center;
  background-size: 15px 15px;
  width: 100%;
  height: 30px;
  color: $main_white;
  cursor: pointer;
}
.data_management_content_title.on {
  background: url('@/../public/images/caret_down_main.svg') no-repeat left center;
  background-size: 15px 15px;
  color: rgb(1, 250, 254);
}
.data_management_content_main {
  @include flexbox(flex-start, center, column);
  width: 100%;
  margin-bottom: 10px;
}
.data_management_content_map {
  @include flexbox(flex-start, center, column);
}
.data_management_content_map_title_wrap {
  @include flexbox(center, flex-start, row);
  width: 100%;
  height: 30px;
}
.data_management_content_map_title {
  @include flexbox(center, flex-start, row);
  margin-left: 15px;
  padding-left: 20px;
  background: url('@/../public/images/caret_right_white.svg') no-repeat left center;
  background-size: 15px 15px;
  height: 100%;
  color: $main_white;
  cursor: pointer;
}
.data_management_content_map_title.on {
  background: url('@/../public/images/caret_down_main.svg') no-repeat left center;
  background-size: 15px 15px;
  color: rgb(1, 250, 254);
}
.data_management_content_map_title_checkbox {
  margin-left: 15px;
  height: 20px;
  width: 20px;
  border-radius: 3px;
  box-shadow: $main_box_shadow;
  background-color: rgb(32, 32, 32);
  cursor: pointer;
}
.data_management_content_map_title_checkbox.on {
  background-image: url('@/../public/images/check.svg');
  background-size: 15px, 15px;
  background-repeat: no-repeat;
  background-position: center;
  background-color: $main_color;
}
.data_management_content_map_main {
  @include flexbox(flex-start, center, column);
  width: 100%;
}
.data_management_content_image_wrap {
  @include flexbox(center, flex-start, row);
  width: 100%;
  height: 30px;
}
.data_management_content_image {
  @include flexbox(center, flex-start, row);
  margin-left: 50px;
  height: 100%;
  color: $main_white;
}
.data_management_content_image_checkbox {
  width: 20px;
  height: 20px;
  margin: 0px 15px 0px 15px;
  border-radius: 3px;
  box-shadow: $main_box_shadow;
  background-color: rgb(32, 32, 32);
  cursor: pointer;
}
.data_management_content_image_checkbox.on {
  background-image: url('@/../public/images/check.svg');
  background-size: 15px, 15px;
  background-repeat: no-repeat;
  background-position: center;
  background-color: $main_color;
}
.data_management_content_another_image_wrap {
  @include flexbox(center, center, row);
  position: relative;
  height: 30px;
  width: 30px;
}
.data_management_content_image_spinner {
  width: 25px;
  height: 25px;
  background-image: url('@/../public/images/spinner_white.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  animation: spin 1s linear infinite;
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.data_management_content_image_complete {
  width: 20px;
  height: 20px;
  background-image: url('@/../public/images/check_green.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.data_management_content_image_text {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  @include flexbox(center, center, row);
  font-size: 0.8rem;
  color: $main_white;
}
.data_management_footer {
  @include flexbox(center, flex-start, row);
  width: 100%;
  height: 30px;
  font-size: 1.4rem;
  font-weight: bold;
}
.data_management_footer_message {
  @include flexbox(center, flex-start, row);
  width: calc(100% - 110px);
  height: 100%;
  color: red;
}
.data_management_footer_button_wrap {
  @include flexbox(center, space-between, row);
  width: 110px;
  height: 100%;
}
.data_management_footer_button {
  @include flexbox;
  width: 50px;
  height: 100%;
}
.data_management_modal_button {
  @include flexbox;
  width: 50px;
  height: 30px;
  font-size: 1.4rem;
  font-weight: bold;
}
.data_management_modal_wrap {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
}
.data_management_modal {
  position: absolute;
  top: calc(50% - 50px);
  left: calc(50% - 150px);
  @include flexbox(center, flex-start, column);
  padding: 0px 20px 0px 20px;
  width: 300px;
  height: 100px;
  background: $main_black;
}
.data_management_modal_title_wrap {
  @include flexbox(center, flex-start, row);
  width: 100%;
  height: 50px;
  border-bottom: 1px solid $black_353542;
  color: $main_white;
  font-size: 2rem;
  font-weight: 700;
}
.data_management_modal_title {
  @include flexbox(center, flex-start, row);
  width: calc(100% - 30px);
  height: 100%;
}
.data_management_modal_close {
  width: 20px;
  height: 20px;
  background: url('@/../public/images/x_white.svg') no-repeat center;
  background-size: contain;
  cursor: pointer;
}
.data_management_modal_content {
  @include flexbox(center, space-between, row);
  width: 100%;
  height: 50px;
}
.data_management_modal_select {
  @include flexbox(center, flex-start, row);
  padding-left: 10px;
  width: 150px;
  height: 25px;
  border-radius: 5px;
  font-size: 12px;
  color: $main_white;
  box-shadow: 0px 0px 4px 2px rgba(1, 250, 254, 0.3);
  background: rgba(23, 23, 23, 0.6) url('@/../public/images/chevronDown.svg') no-repeat
    right 10px center;
  background-size: 12px 12px;
  cursor: pointer;
}
.data_management_modal_select_option_wrap {
  position: absolute;
  @include flexbox(center, flex-start, column);
  left: 20px;
  top: 95px;
  width: 150px;
  height: 0px;
  padding: 0px 10px 0px 10px;
  border-radius: 5px;
  font-size: 12px;
  color: $main_white;
  box-shadow: 0px 0px 4px 2px rgba(1, 250, 254, 0.3);
  background: rgba(23, 23, 23);
  z-index: 22;
  cursor: pointer;
}
.data_management_modal_select_option {
  @include flexbox(center, flex-start);
  width: 100%;
  height: 25px;
  border-bottom: 1px solid rgba(127, 253, 239, 0.5);
}
.data_management_modal_select_option:last-of-type {
  border: none;
}
.data_management_modal_select_option:hover {
  color: rgba(1, 250, 254, 0.5);
}
</style>
